import { useCallback } from 'react';

import { usePintoVouchersUpdateMutation } from '../core/graphql/operation-hooks';
import { UpdatePintoVouchersInput } from '../core/graphql/types';

const useMutationPintoVouchersUpdate = () => {
  const [mutatePintoVouchersUpdate] = usePintoVouchersUpdateMutation();

  const updateVouchers = useCallback(
    async (codes: string, input: UpdatePintoVouchersInput) => {
      const resp = await mutatePintoVouchersUpdate({
        variables: { codes: [codes], input },
        refetchQueries: ['PintoVouchers', 'PintoVoucher'],
      });

      return resp.data?.updatePintoVouchers.success;
    },
    [mutatePintoVouchersUpdate],
  );

  return {
    updateVouchers,
  };
};

export default useMutationPintoVouchersUpdate;
