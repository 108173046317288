import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FiLink } from 'react-icons/fi';
import { MdDelete, MdVisibilityOff, MdVisibility } from 'react-icons/md';
import { Link, useHistory, useLocation } from 'react-router-dom';

import AboutDetailPresenter from '../../app/coupons/presenters/AboutDetailPresenter';
import { PINTO_BOOK_BASE_URL } from '../../app/ebooks/presenters/AboutDetailPresenter';
import ConfirmModal from '../../app/modals/ConfirmModal';
import Breadcrumbs from '../../components/Breadcrumbs';
import Button from '../../components/Button';
import LoadingModal from '../../components/modal/LoadingModal';
import ToastComponent, { IToastType } from '../../components/Toast/ToastComponent';
import { PintoVoucherParticipatingItem, PintoVoucherStatus } from '../../core/graphql/types';
import useMutationPintoVouchersDelete from '../../hooks/useMutationPintoVouchersDelete';
import useMutationPintoVouchersUpdate from '../../hooks/useMutationPintoVouchersUpdate';
import useQueryPintoVoucher from '../../hooks/useQueryPintoVoucher';

function CouponDetailPage() {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const code = searchParams.get('code');
  const { pintoVoucherData: pintoVoucher, onFetchPintoVoucher, isLoadingPintoVoucher } = useQueryPintoVoucher();
  const { deleteVouchers } = useMutationPintoVouchersDelete();
  const { updateVouchers } = useMutationPintoVouchersUpdate();

  const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false);
  const [showConfirmRemoveEbookModal, setShowConfirmRemoveEbookModal] = useState<boolean>(false);
  const [showConfirmHideEbookModal, setShowConfirmHiedEbookModal] = useState<boolean>(false);

  useEffect(() => {
    if (code) onFetchPintoVoucher(code);
  }, [code, onFetchPintoVoucher]);

  const onHideCoupon = useCallback(async () => {
    if (code) {
      setShowLoadingModal(true);
      const isSuccess = await updateVouchers(code, { enabled: false });
      setShowLoadingModal(false);
      if (isSuccess) {
        ToastComponent({ label: 'Hide coupon successfully ', type: IToastType.SUCCESS });
      }
    }
  }, [code, updateVouchers]);

  const onUnHideCoupon = useCallback(async () => {
    if (code) {
      setShowLoadingModal(true);
      const isSuccess = await updateVouchers(code, { enabled: true });
      setShowLoadingModal(false);
      if (isSuccess) {
        ToastComponent({ label: 'Publish coupon successfully ', type: IToastType.SUCCESS });
      }
    }
  }, [code, updateVouchers]);

  const onRemoveCoupon = useCallback(async () => {
    if (code) {
      setShowLoadingModal(true);
      const isSuccess = await deleteVouchers(code);
      setShowLoadingModal(false);

      if (isSuccess) {
        ToastComponent({ label: 'Remove coupon successfully ', type: IToastType.SUCCESS });
        history.push('/coupons');
      }
    }
  }, [code, deleteVouchers, history]);

  const onConfirmHideUnHideEbook = useCallback(() => {
    if (pintoVoucher?.enabled) {
      onHideCoupon();
    } else {
      onUnHideCoupon();
    }
    setShowConfirmHiedEbookModal(false);
  }, [onHideCoupon, onUnHideCoupon, pintoVoucher?.enabled]);

  return (
    <div className="space-y-12">
      {isLoadingPintoVoucher && !pintoVoucher ? (
        <div className="w-full btn btn-outline btn-disabled bg-systemGrays04LabelQuaternary border-none loading h-[100px]" />
      ) : (
        <>
          <div className="sticky top-0 z-50 px-5 bg-componentsBgGrouped02">
            {/* section: breadcrumbs */}
            <Breadcrumbs items={[{ name: 'Coupons', url: '/coupons' }, { name: pintoVoucher?.title || 'Coupon Detail' }]} />
            {/* section: title */}
            <div className="flex flex-col justify-between pt-5 md:flex-row">
              <div className="w-[70%] h-full">
                <h1 className="font-dbh text-[28px] break-words">{pintoVoucher?.title || 'Coupon Detail'}</h1>
              </div>

              <div className="flex items-center space-x-12">
                <Link to="/coupons">
                  <Button type="outline" color="gray" className="space-x-4">
                    Back
                  </Button>
                </Link>

                <Button
                  disabled={pintoVoucher?.status !== PintoVoucherStatus.Active || !pintoVoucher?.enabled || !pintoVoucher.display}
                  onClick={() => window.open(`${PINTO_BOOK_BASE_URL}/all-coupon?code=${pintoVoucher?.code}`)}
                  type="outline"
                  color="black"
                  className="space-x-4"
                >
                  <FiLink className="font-bold text-24" />
                  <span>พรีวิวคูปอง</span>
                </Button>

                <Button
                  disabled={pintoVoucher?.status !== PintoVoucherStatus.Scheduled}
                  onClick={() => setShowConfirmRemoveEbookModal(true)}
                  type="outline"
                  color="red"
                  className="space-x-4"
                >
                  <MdDelete size={24} />
                  <div>Remove</div>
                </Button>

                <div className="dropdown dropdown-bottom dropdown-end">
                  <label tabIndex={0} className="m-1">
                    <Button
                      disabled={pintoVoucher?.status !== PintoVoucherStatus.Active}
                      type="outline"
                      color="black"
                      className="space-x-4"
                      onClick={() => setShowConfirmHiedEbookModal(true)}
                    >
                      <input type="checkbox" className="toggle toggle-accent" disabled={pintoVoucher?.status !== PintoVoucherStatus.Active} checked={pintoVoucher?.enabled} />
                      <div
                        className={classNames({
                          'text-colorsBrandWarmBlack02LabelSecondary': pintoVoucher?.status === PintoVoucherStatus.Active && !pintoVoucher?.enabled,
                          'text-colorsPintoBlueprint01Primary': pintoVoucher?.status === PintoVoucherStatus.Active && pintoVoucher?.enabled,
                        })}
                      >
                        {pintoVoucher?.status === PintoVoucherStatus.Active ? (!pintoVoucher?.enabled ? 'Unpublish' : 'Publish') : pintoVoucher?.status}
                      </div>
                    </Button>
                  </label>
                  {showConfirmHideEbookModal && (
                    <div tabIndex={0} className="dropdown-content card card-compact p-2 w-[350px] shadow bg-base-100">
                      <div className="card-body space-y-12 !p-24">
                        <h3 className="card-title font-dbh !mb-0 flex items-center">
                          <span className="mr-4">{pintoVoucher?.enabled ? <MdVisibilityOff size={24} /> : <MdVisibility size={24} />}</span>
                          {`${pintoVoucher?.enabled ? 'Unpublish' : 'Publish'} Confirmation`}
                        </h3>
                        <div>
                          <p className="font-tt">{`Are you confirm to ${pintoVoucher?.enabled ? 'unpublish' : 'publish'}: `}</p>
                          <p className="font-tt">{pintoVoucher?.code}?</p>
                        </div>
                        <div className="flex justify-center w-full space-x-6">
                          <button className="btn btn-outline" onClick={() => setShowConfirmHiedEbookModal(false)}>
                            Cancel
                          </button>
                          <button className="btn btn-error" onClick={onConfirmHideUnHideEbook}>
                            Confirm
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* section: main */}
            <div className="mb-0 divider opacity-10"></div>
          </div>

          {/* price */}
          {/* <PriceDetailPresenter
            isFree={pintoVoucher?.isFree || false}
            webPrice={pintoEbookData?.originalPriceThb || 0}
            webDiscountRatio={pintoEbookData?.discount?.ratio || undefined}
            webDiscountPrice={pintoEbookData?.discount?.thb || undefined}
            googlePriceTier={pintoEbookData?.googleOriginalPriceTier || undefined}
            applePriceTier={pintoEbookData?.appleOriginalPriceTier || undefined}
            discountGooglePriceTier={pintoEbookData?.discount?.googlePriceTier || undefined}
            discountApplePriceTier={pintoEbookData?.discount?.applePriceTier || undefined}
            discountStartDate={pintoEbookData?.discount?.startedAt ? new Date(pintoEbookData?.discount?.startedAt) : undefined}
            discountEndDate={pintoEbookData?.discount?.endedAt ? getDiscountEndDateFromQuery(pintoEbookData?.discount?.endedAt) : undefined}
          /> */}

          {/* about */}
          <AboutDetailPresenter
            code={pintoVoucher?.code}
            title={pintoVoucher?.title}
            description={pintoVoucher?.description || '-'}
            conditions={pintoVoucher?.conditions}
            discountAmount={pintoVoucher?.discountAmount ?? 0}
            discountType={pintoVoucher?.discountType || '-'}
            maximumDiscountAmount={pintoVoucher?.maximumDiscountAmount ?? 0}
            expiredAt={pintoVoucher?.expiredAt}
            startedAt={pintoVoucher?.startedAt}
            membershipStartedAt={pintoVoucher?.membershipStartedAt}
            minimumSpendThb={pintoVoucher?.minimumSpendThb ?? 0}
            status={pintoVoucher?.status}
            redeemedCounts={pintoVoucher?.redeemedCounts ?? 0}
            maximumRedeemedCounts={pintoVoucher?.maximumRedeemedCounts ?? 0}
            participatingItems={(pintoVoucher?.participatingItems as PintoVoucherParticipatingItem) ?? undefined}
          />

          <div className="flex flex-col space-y-12 md:flex-row md:justify-between md:space-y-0 md:space-x-24">
            {/* status */}
            {/* <StatusDetailPresenter
              createdAt={pintoEbookData?.createdAt}
              createdBy={pintoEbookData?.summaryEbookLog?.created?.actor.displayName || ''}
              publishedAt={pintoEbookData?.publishedAt}
              updatedAt={pintoEbookData?.updatedAt}
              updatedBy={pintoEbookData?.summaryEbookLog?.latestUpdated?.actor.displayName || ''}
            /> */}
          </div>
        </>
      )}
      {showLoadingModal && <LoadingModal />}

      {showConfirmRemoveEbookModal && (
        <ConfirmModal
          onClose={() => setShowConfirmRemoveEbookModal(false)}
          onConFirm={onRemoveCoupon}
          title="Remove Confirmation"
          subTitle={`Are you confirm to remove : ${pintoVoucher?.title}?`}
        />
      )}
    </div>
  );
}

export default CouponDetailPage;
