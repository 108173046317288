import { useCallback } from 'react';

import { usePintoVouchersUploadMutation } from '../core/graphql/operation-hooks';
import { UploadPintoVouchersInput } from '../core/graphql/types';

const useMutatePintoVouchersUpload = () => {
  const [mutatePintoVouchersUpload, { loading: isLoadingMutatePintoVouchersUpload }] = usePintoVouchersUploadMutation({ errorPolicy: 'all' });

  const onMutatePintoVouchersUpload = useCallback(
    async (input: UploadPintoVouchersInput) => {
      const variables = { input };
      const result = await mutatePintoVouchersUpload({ variables, refetchQueries: ['PintoVouchers', 'PintoVoucher'] });
      return result;
    },
    [mutatePintoVouchersUpload],
  );

  return {
    onMutatePintoVouchersUpload,
    isLoadingMutatePintoVouchersUpload,
  };
};

export default useMutatePintoVouchersUpload;
