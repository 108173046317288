export interface IErrorItem {
  code: string;
  errorMessages: string[];
}

export interface IPropsUploadCouponErrorModal {
  onConFirm?: () => void;
  title?: string;
  errorItems?: IErrorItem[];
  confirmText?: string;
}

const UploadCouponErrorModal = (props: IPropsUploadCouponErrorModal) => {
  const { onConFirm, confirmText, title, errorItems } = props;

  return (
    <>
      <div className="modal modal-open">
        <div className="relative modal-box max-w-[76vw] w-[76vw]">
          <h3 className="text-lg font-bold font-dbh">{title || 'Error Transactions'}</h3>

          <div className="rounded-md mt-12 overflow-auto h-[60vh] scrollbar-hide">
            <table className="table w-full table-zebra font-tt">
              <thead>
                <tr>
                  <th>No</th>
                  <th className="sticky z-50 left-12">Code</th>
                  <th>Error Message</th>
                </tr>
              </thead>
              <tbody>
                {errorItems?.map((errorItem, index) => (
                  <tr key={`${index}-${errorItem.code}`}>
                    <th className="font-normal">{index + 1}</th>
                    <td className="sticky z-50 left-12">{errorItem.code ?? '-'}</td>
                    <td>
                      <ul className="flex flex-col flex-1 space-y-4 list-disc pl-[18px]">
                        {errorItem && errorItem.errorMessages.length > 0 ? (
                          errorItem.errorMessages?.map((err, idx) => <li key={idx}>{err}</li>)
                        ) : (
                          <div className="text-colorsBrandWarmBlack02LabelSecondary">-</div>
                        )}
                      </ul>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="flex justify-end w-full pt-24 space-x-4">
            <button className="btn btn-error" onClick={onConFirm}>
              {confirmText || 'OK'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadCouponErrorModal;
