import { useCallback } from 'react';

import { usePintoVouchersLazyQuery } from '../core/graphql/operation-hooks';
import { PintoVouchersFilter } from '../core/graphql/types';

const INITIAL_LIMIT = 10;

const useQueryPintoVouchers = () => {
  const [queryPintoVouchers, { data, variables, loading }] = usePintoVouchersLazyQuery({ fetchPolicy: 'network-only' });

  const fetchPintoVouchers = useCallback(
    (filter?: PintoVouchersFilter) => {
      queryPintoVouchers({
        variables: {
          filter: {
            ...filter,
            limit: INITIAL_LIMIT,
          },
        },
      });
    },
    [queryPintoVouchers],
  );

  const onFetchMorePintoVouchers = useCallback(() => {
    if (loading || !data?.pintoVouchers?.pageInfo.hasNextPage) return;
    fetchPintoVouchers?.({
      ...variables?.filter,
    });
  }, [loading, data?.pintoVouchers?.pageInfo.hasNextPage, fetchPintoVouchers, variables?.filter]);

  return {
    fetchPintoVouchers,
    onFetchMorePintoVouchers,
    pintoVouchers: data?.pintoVouchers,
    isLoadingPintoVouchers: loading,
  };
};

export default useQueryPintoVouchers;
