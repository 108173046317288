// This file was automatically generated and should not be edited.

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import * as Types from './types';
const defaultOptions = {} as const;
export const EbookDiscountCampaignFragmentDoc = gql`
  fragment EbookDiscountCampaign on EbookDiscountCampaign {
    _id
    title
    status
    startedAt
    endedAt
    fileName
    fileUrl
    createdAt
    updatedAt
  }
`;
export const EditEbookFileFragmentDoc = gql`
  fragment EditEbookFile on EditEbookFile {
    type
    sampleFilename
    sampleFileUrl
    mainFilename
    mainFileUrl
    requestedFileUrl
    requestedFileName
    requestedFileType
    status
    requestedSampleFileType
    lastSampleChapterNumber
    lastSampleChapterName
    isAutoGenerateSampleFile
  }
`;
export const EditEbookFragmentDoc = gql`
  fragment EditEbook on EditEbook {
    _id
    ebookId
    status
    categoryId
    correctionEbookFiles {
      ...EditEbookFile
    }
    userNote
    rejectNotes
    activatedAt
    createdAt
    updatedAt
    ebook {
      _id
      title
      coverImageUrl
      status
      user {
        _id
        username
      }
    }
  }
  ${EditEbookFileFragmentDoc}
`;
export const SectionTargetFragmentDoc = gql`
  fragment SectionTarget on SectionTarget {
    __typename
    ... on Feed {
      feedId: _id
    }
    ... on Link {
      url
    }
    ... on Explore {
      exploreType
      completed
      sortBy
      isFree
      contentType
      categoryTagId
      contentRatingIds
    }
    ... on User {
      userId: _id
    }
    ... on SearchLink {
      searchType
      searchText
      completed
      contentRatingIds
      isFree
      contentType
      categoryTagId
    }
    ... on SectionTargetSection {
      sectionTargetSectionId: _id
    }
    ... on SectionTargetProductSet {
      sectionTargetProductSetId: _id
    }
    ... on ExploreProductSet {
      sortBy
      sortType
      categoryId
    }
  }
`;
export const SectionFragmentDoc = gql`
  fragment Section on Section {
    _id
    pref
    productType
    contentType
    camelCaseLayoutId
    isNCContent
    isActivated
    title
    target {
      ...SectionTarget
    }
    configItems
    items
    note
    createdAt
    updatedAt
  }
  ${SectionTargetFragmentDoc}
`;
export const FeedFragmentDoc = gql`
  fragment Feed on Feed {
    _id
    pref
    productType
    contentType
    title
    description
    coverImage
    bannerImage
    sections {
      ...Section
    }
    meta
    slug
    createdAt
    updatedAt
  }
  ${SectionFragmentDoc}
`;
export const BookPageAdFragmentDoc = gql`
  fragment BookPageAd on BookPageAd {
    _id
    title
    note
    bookId
    destinationUrl
    createdAt
  }
`;
export const CurrentUserFragmentDoc = gql`
  fragment CurrentUser on User {
    _id
    type
    username
    displayName
    profileImage
    permission {
      _id
      viewUsers
      updateUsers
      removeUsers
      editUserPermissions
      viewBooks
      updateBooks
      removeBooks
      editChapters
      viewEbooks
      updateEbooks
      removeEbooks
      editEbooks
      viewProductSets
      updateProductSets
      removeProductSets
      feeds
      sections
      vouchers
      metrics
      bestSeller
      transactions
      exportRaws
      taxDeductions
      tags
    }
  }
`;
export const EbookBundleFragmentDoc = gql`
  fragment EbookBundle on EbookBundle {
    _id
    title
    description
    coverImageUrl
    bannerImageUrl
    ebookCount
    status
    createdAt
    updatedAt
    startedAt
    priceThb
    originalPriceThb
    discountRatio
    googleDiscountRatio
    userId
  }
`;
export const PriceTierFragmentDoc = gql`
  fragment PriceTier on AppPriceTier {
    _id
    platform
    thb
    usd
  }
`;
export const PintoCategoryFragmentDoc = gql`
  fragment PintoCategory on PintoCategory {
    _id
    name
    canSetPrice
    contentType
    __typename
  }
`;
export const EbookDetailFragmentDoc = gql`
  fragment EbookDetail on Ebook {
    _id
    title
    description
    coverImageUrl
    bannerImageUrl
    status
    correctionEbookStatus
    synopsis
    isFree
    readingType
    createdAt
    updatedAt
    publishedAt
    authorNames
    translatorNames
    viewsCount
    purchasesCount
    totalSales
    earningsRatio
    originalPriceThb
    priceThb
    discountRatio
    coverPriceThb
    discountRatio
    appleOriginalPriceTier {
      ...PriceTier
    }
    googleOriginalPriceTier {
      ...PriceTier
    }
    discount {
      endedAt
      ratio
      startedAt
      thb
      googlePriceTier {
        ...PriceTier
      }
      applePriceTier {
        ...PriceTier
      }
    }
    isbn
    pagesCount
    originalLanguage {
      name
      code
    }
    language {
      name
      code
    }
    productSet {
      _id
      title
      itemsCount
    }
    productSetId
    productSetVol
    ebookFiles {
      mainFileUrl
      mainFilename
      sampleFileUrl
      sampleFilename
      sizeInKB
      type
    }
    tags {
      _id
      name
    }
    category {
      parent {
        ...PintoCategory
        parent {
          ...PintoCategory
        }
      }
      ...PintoCategory
    }
    user {
      _id
      displayName
      username
      profileImage
      type
      email
      isPremium
    }
    summaryEbookLog {
      created {
        actor {
          displayName
        }
      }
      latestUpdated {
        actor {
          displayName
        }
      }
    }
    correction {
      status
      category {
        _id
        name
        canSetPrice
        contentType
        parentId
        parent {
          _id
          name
          canSetPrice
          contentType
          parentId
          parent {
            name
            _id
          }
        }
      }
      correctionEbookFiles {
        mainFileUrl
        sampleFileUrl
        type
        mainFilename
        sampleFilename
        status
      }
      rejectNotes
    }
    writingType
    subscriptionTitleId
    subscriptionTitle {
      _id
      title
    }
  }
  ${PriceTierFragmentDoc}
  ${PintoCategoryFragmentDoc}
`;
export const EbookFileFragmentDoc = gql`
  fragment EbookFile on EbookFile {
    mainFileUrl
    pagesCount
    sampleFileUrl
    type
  }
`;
export const LanguageFragmentDoc = gql`
  fragment Language on Language {
    code
    name
  }
`;
export const PageInfoFragmentDoc = gql`
  fragment PageInfo on PageInfo {
    startCursor
    endCursor
    hasNextPage
    hasPreviousPage
  }
`;
export const PintoProductSetFragmentDoc = gql`
  fragment PintoProductSet on PintoProductSet {
    _id
    activatedAt
    coverImageUrl
    createdAt
    description
    itemsCount
    hasItemVol
    publishedAt
    status
    title
    updatedAt
    user {
      _id
      username
      __typename
    }
  }
`;
export const PintoVoucherFragmentDoc = gql`
  fragment PintoVoucher on PintoVoucher {
    code
    conditions
    description
    createdAt
    description
    display
    discountAmount
    discountThb
    discountType
    expiredAt
    startedAt
    enabled
    isMembershipEarlyBird
    isMembershipOnly
    maximumDiscountAmount
    maximumRedeemedCounts
    maximumRedeemedCountsPerMembership
    maximumRedeemedCountsPerUser
    membershipStartedAt
    minimumSpendThb
    myRedeemedCounts
    participatingItems {
      itemType
      itemIds
      items {
        ... on PintoCategory {
          _id
          name
        }
        ... on User {
          username
        }
      }
    }
    redeemedCounts
    subType
    status
    title
    type
  }
`;
export const UnionSectionItemFragmentDoc = gql`
  fragment UnionSectionItem on UnionSectionItem {
    __typename
    ... on Book {
      bookId: _id
    }
    ... on Ebook {
      ebookId: _id
    }
    ... on Section {
      _id
      title
      note
      isNCContent
      pref
      productType
      contentType
    }
    ... on Feed {
      _id
    }
  }
`;
export const PopupNodeFragmentDoc = gql`
  fragment PopupNode on Popup {
    _id
    pref
    type
    updatedAt
    createdAt
    isActivated
    itemsCount {
      activeItems
      totalItems
    }
    items {
      _id
      type
      note
      config {
        __typename
        popupImageUrl
        ... on PopupItemConfigBook {
          _id
        }
        ... on PopupItemConfigEbook {
          _id
        }
        ... on PopupItemConfigProductSet {
          _id
        }
        ... on PopupItemConfigSection {
          _id
        }
        ... on PopupItemConfigFeed {
          slug
        }
        ... on PopupItemConfigLink {
          url
        }
        ... on PopupItemConfigSearch {
          searchType
          contentType
          searchText
          completed
          contentRatingIds
          isFree
          categoryTagId
        }
        ... on PopupItemConfigExploreBook {
          contentType
          sortByBook: sortBy
          completed
          categoryTagId
          contentRatingIds
          isFree
        }
        ... on PopupItemConfigExploreEbook {
          contentType
          sortByEbook: sortBy
          categoryTagId
          contentRatingIds
          isFree
        }
      }
      schedules {
        _id
        startedAt
        endedAt
      }
    }
    unionItems {
      ...UnionSectionItem
    }
  }
  ${UnionSectionItemFragmentDoc}
`;
export const PopupNodeWithoutItemsFragmentDoc = gql`
  fragment PopupNodeWithoutItems on Popup {
    _id
    pref
    type
    updatedAt
    createdAt
    isActivated
    itemsCount {
      activeItems
      totalItems
    }
  }
`;
export const SectionV3FragmentDoc = gql`
  fragment SectionV3 on SectionV3 {
    _id
    camelCaseLayoutId
    title
    pref
    productType
    contentType
    note
    isNCContent
    isActivated
    unionItems {
      __typename
      ... on Book {
        bookId: _id
        title
        viewsCount
        coverImage
        completed
        placeholderBackgroundColor
        authorName
        translatorName
        categoryTag {
          _id
          name
        }
        contentRating {
          _id
          name
        }
        sectionItemUser: user {
          displayName
        }
      }
      ... on Ebook {
        _id
        coverImage
        title
        placeholderBackgroundColor
        authorName
        translatorName
        discountPriceTierDetail
        categoryTag {
          name
        }
        contentRating {
          _id
          name
        }
        user {
          displayName
        }
      }
    }
    schedules {
      _id
      startedAt
      endedAt
    }
    target {
      ...SectionTarget
    }
  }
  ${SectionTargetFragmentDoc}
`;
export const SubscriptionTitlePriceTierFragmentDoc = gql`
  fragment SubscriptionTitlePriceTier on SubscriptionTitlePriceTier {
    _id
    thb
    usd
  }
`;
export const SubscriptionTitleDetailFragmentDoc = gql`
  fragment SubscriptionTitleDetail on SubscriptionTitle {
    _id
    title
    slug
    user {
      _id
      displayName
      username
      profileImage
      type
      email
      isPremium
    }
    ebooksCount
    publishedEbooksCount
    packages {
      _id
      title
      packageType
      applePriceTierId
      googlePriceTierId
      applePriceTier {
        ...SubscriptionTitlePriceTier
      }
      googlePriceTier {
        ...SubscriptionTitlePriceTier
      }
      priceThb
      period
      appleProductId
      googleProductId
      isEnabled
      deletedAt
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
  ${SubscriptionTitlePriceTierFragmentDoc}
`;
export const TagFragmentDoc = gql`
  fragment Tag on Tag {
    _id
    name
    productsCount
    searchesCount
  }
`;
export const SectionV3WithoutItemsFragmentDoc = gql`
  fragment SectionV3WithoutItems on SectionV3 {
    _id
    camelCaseLayoutId
    title
    pref
    productType
    contentType
    note
    isNCContent
    isActivated
    schedules {
      _id
      startedAt
      endedAt
    }
  }
`;
export const TemplateFragmentDoc = gql`
  fragment Template on Template {
    _id
    contentType
    isActivated
    placement
    pref
    slug
    productType
    contentType
    updatedAt
    categoryTag {
      _id
      name
    }
    meta {
      ogImage
      ogTitle
      ogDescription
    }
    sections {
      ...SectionV3WithoutItems
    }
  }
  ${SectionV3WithoutItemsFragmentDoc}
`;
export const TemplateNodeFragmentDoc = gql`
  fragment TemplateNode on Template {
    _id
    contentType
    isActivated
    placement
    pref
    productType
    updatedAt
    categoryTag {
      _id
      name
    }
  }
`;
export const CreateAndSubmitReviewEbookDocument = gql`
  mutation CreateAndSubmitReviewEbook($input: CreateAndSubmitReviewEbookInput!) {
    createAndSubmitReviewEbook(input: $input) {
      ...EbookDetail
    }
  }
  ${EbookDetailFragmentDoc}
`;
export type CreateAndSubmitReviewEbookMutationFn = Apollo.MutationFunction<Types.CreateAndSubmitReviewEbookMutation, Types.CreateAndSubmitReviewEbookMutationVariables>;

/**
 * __useCreateAndSubmitReviewEbookMutation__
 *
 * To run a mutation, you first call `useCreateAndSubmitReviewEbookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAndSubmitReviewEbookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAndSubmitReviewEbookMutation, { data, loading, error }] = useCreateAndSubmitReviewEbookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAndSubmitReviewEbookMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.CreateAndSubmitReviewEbookMutation, Types.CreateAndSubmitReviewEbookMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateAndSubmitReviewEbookMutation, Types.CreateAndSubmitReviewEbookMutationVariables>(CreateAndSubmitReviewEbookDocument, options);
}
export type CreateAndSubmitReviewEbookMutationHookResult = ReturnType<typeof useCreateAndSubmitReviewEbookMutation>;
export type CreateAndSubmitReviewEbookMutationResult = Apollo.MutationResult<Types.CreateAndSubmitReviewEbookMutation>;
export type CreateAndSubmitReviewEbookMutationOptions = Apollo.BaseMutationOptions<Types.CreateAndSubmitReviewEbookMutation, Types.CreateAndSubmitReviewEbookMutationVariables>;
export const CreateBookPageAdDocument = gql`
  mutation CreateBookPageAd($input: CreateBookPageAdInput!) {
    createBookPageAd(input: $input) {
      ...BookPageAd
    }
  }
  ${BookPageAdFragmentDoc}
`;
export type CreateBookPageAdMutationFn = Apollo.MutationFunction<Types.CreateBookPageAdMutation, Types.CreateBookPageAdMutationVariables>;

/**
 * __useCreateBookPageAdMutation__
 *
 * To run a mutation, you first call `useCreateBookPageAdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookPageAdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookPageAdMutation, { data, loading, error }] = useCreateBookPageAdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBookPageAdMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateBookPageAdMutation, Types.CreateBookPageAdMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateBookPageAdMutation, Types.CreateBookPageAdMutationVariables>(CreateBookPageAdDocument, options);
}
export type CreateBookPageAdMutationHookResult = ReturnType<typeof useCreateBookPageAdMutation>;
export type CreateBookPageAdMutationResult = Apollo.MutationResult<Types.CreateBookPageAdMutation>;
export type CreateBookPageAdMutationOptions = Apollo.BaseMutationOptions<Types.CreateBookPageAdMutation, Types.CreateBookPageAdMutationVariables>;
export const CreateDraftEbookDocument = gql`
  mutation CreateDraftEbook($input: CreateDraftEbookInput!) {
    createDraftEbook(input: $input) {
      _id
    }
  }
`;
export type CreateDraftEbookMutationFn = Apollo.MutationFunction<Types.CreateDraftEbookMutation, Types.CreateDraftEbookMutationVariables>;

/**
 * __useCreateDraftEbookMutation__
 *
 * To run a mutation, you first call `useCreateDraftEbookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDraftEbookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDraftEbookMutation, { data, loading, error }] = useCreateDraftEbookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDraftEbookMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateDraftEbookMutation, Types.CreateDraftEbookMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateDraftEbookMutation, Types.CreateDraftEbookMutationVariables>(CreateDraftEbookDocument, options);
}
export type CreateDraftEbookMutationHookResult = ReturnType<typeof useCreateDraftEbookMutation>;
export type CreateDraftEbookMutationResult = Apollo.MutationResult<Types.CreateDraftEbookMutation>;
export type CreateDraftEbookMutationOptions = Apollo.BaseMutationOptions<Types.CreateDraftEbookMutation, Types.CreateDraftEbookMutationVariables>;
export const CreatePintoSubscriptionTitleDocument = gql`
  mutation CreatePintoSubscriptionTitle($input: CreatePintoSubscriptionTitleInput!) {
    createPintoSubscriptionTitle(input: $input) {
      _id
    }
  }
`;
export type CreatePintoSubscriptionTitleMutationFn = Apollo.MutationFunction<Types.CreatePintoSubscriptionTitleMutation, Types.CreatePintoSubscriptionTitleMutationVariables>;

/**
 * __useCreatePintoSubscriptionTitleMutation__
 *
 * To run a mutation, you first call `useCreatePintoSubscriptionTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePintoSubscriptionTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPintoSubscriptionTitleMutation, { data, loading, error }] = useCreatePintoSubscriptionTitleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePintoSubscriptionTitleMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.CreatePintoSubscriptionTitleMutation, Types.CreatePintoSubscriptionTitleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreatePintoSubscriptionTitleMutation, Types.CreatePintoSubscriptionTitleMutationVariables>(CreatePintoSubscriptionTitleDocument, options);
}
export type CreatePintoSubscriptionTitleMutationHookResult = ReturnType<typeof useCreatePintoSubscriptionTitleMutation>;
export type CreatePintoSubscriptionTitleMutationResult = Apollo.MutationResult<Types.CreatePintoSubscriptionTitleMutation>;
export type CreatePintoSubscriptionTitleMutationOptions = Apollo.BaseMutationOptions<
  Types.CreatePintoSubscriptionTitleMutation,
  Types.CreatePintoSubscriptionTitleMutationVariables
>;
export const CreatePopupDocument = gql`
  mutation CreatePopup($input: CreatePopupInput!) {
    createPopup(input: $input) {
      ...PopupNode
    }
  }
  ${PopupNodeFragmentDoc}
`;
export type CreatePopupMutationFn = Apollo.MutationFunction<Types.CreatePopupMutation, Types.CreatePopupMutationVariables>;

/**
 * __useCreatePopupMutation__
 *
 * To run a mutation, you first call `useCreatePopupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePopupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPopupMutation, { data, loading, error }] = useCreatePopupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePopupMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreatePopupMutation, Types.CreatePopupMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreatePopupMutation, Types.CreatePopupMutationVariables>(CreatePopupDocument, options);
}
export type CreatePopupMutationHookResult = ReturnType<typeof useCreatePopupMutation>;
export type CreatePopupMutationResult = Apollo.MutationResult<Types.CreatePopupMutation>;
export type CreatePopupMutationOptions = Apollo.BaseMutationOptions<Types.CreatePopupMutation, Types.CreatePopupMutationVariables>;
export const CreatePresignedUploadEbookFileDocument = gql`
  mutation CreatePresignedUploadEbookFile($ebookId: ID!, $input: CreatePresignedUploadEbookFileInput!) {
    createPresignedUploadEbookFile(ebookId: $ebookId, input: $input) {
      fields
      url
    }
  }
`;
export type CreatePresignedUploadEbookFileMutationFn = Apollo.MutationFunction<Types.CreatePresignedUploadEbookFileMutation, Types.CreatePresignedUploadEbookFileMutationVariables>;

/**
 * __useCreatePresignedUploadEbookFileMutation__
 *
 * To run a mutation, you first call `useCreatePresignedUploadEbookFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePresignedUploadEbookFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPresignedUploadEbookFileMutation, { data, loading, error }] = useCreatePresignedUploadEbookFileMutation({
 *   variables: {
 *      ebookId: // value for 'ebookId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePresignedUploadEbookFileMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.CreatePresignedUploadEbookFileMutation, Types.CreatePresignedUploadEbookFileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreatePresignedUploadEbookFileMutation, Types.CreatePresignedUploadEbookFileMutationVariables>(CreatePresignedUploadEbookFileDocument, options);
}
export type CreatePresignedUploadEbookFileMutationHookResult = ReturnType<typeof useCreatePresignedUploadEbookFileMutation>;
export type CreatePresignedUploadEbookFileMutationResult = Apollo.MutationResult<Types.CreatePresignedUploadEbookFileMutation>;
export type CreatePresignedUploadEbookFileMutationOptions = Apollo.BaseMutationOptions<
  Types.CreatePresignedUploadEbookFileMutation,
  Types.CreatePresignedUploadEbookFileMutationVariables
>;
export const CreateTagDocument = gql`
  mutation CreateTag($input: CreateTagInput!) {
    createTag(input: $input) {
      _id
      type
      name
      isReserved
      isProhibited
      productsCount
      searchesCount
      subTag {
        _id
        tagId
        categoryTagId
        contentType
        productsCount
        searchesCount
        trendingScore
        createdAt
        updatedAt
      }
    }
  }
`;
export type CreateTagMutationFn = Apollo.MutationFunction<Types.CreateTagMutation, Types.CreateTagMutationVariables>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTagMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateTagMutation, Types.CreateTagMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateTagMutation, Types.CreateTagMutationVariables>(CreateTagDocument, options);
}
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>;
export type CreateTagMutationResult = Apollo.MutationResult<Types.CreateTagMutation>;
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<Types.CreateTagMutation, Types.CreateTagMutationVariables>;
export const EditDraftEbookDocument = gql`
  mutation EditDraftEbook($ebookId: ID!, $input: EditDraftEbookInput!) {
    editDraftEbook(ebookId: $ebookId, input: $input) {
      _id
    }
  }
`;
export type EditDraftEbookMutationFn = Apollo.MutationFunction<Types.EditDraftEbookMutation, Types.EditDraftEbookMutationVariables>;

/**
 * __useEditDraftEbookMutation__
 *
 * To run a mutation, you first call `useEditDraftEbookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDraftEbookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDraftEbookMutation, { data, loading, error }] = useEditDraftEbookMutation({
 *   variables: {
 *      ebookId: // value for 'ebookId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditDraftEbookMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditDraftEbookMutation, Types.EditDraftEbookMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.EditDraftEbookMutation, Types.EditDraftEbookMutationVariables>(EditDraftEbookDocument, options);
}
export type EditDraftEbookMutationHookResult = ReturnType<typeof useEditDraftEbookMutation>;
export type EditDraftEbookMutationResult = Apollo.MutationResult<Types.EditDraftEbookMutation>;
export type EditDraftEbookMutationOptions = Apollo.BaseMutationOptions<Types.EditDraftEbookMutation, Types.EditDraftEbookMutationVariables>;
export const EditEbookDocument = gql`
  mutation EditEbook($id: ID!, $input: EditEbookInput!) {
    editEbook(id: $id, input: $input) {
      _id
    }
  }
`;
export type EditEbookMutationFn = Apollo.MutationFunction<Types.EditEbookMutation, Types.EditEbookMutationVariables>;

/**
 * __useEditEbookMutation__
 *
 * To run a mutation, you first call `useEditEbookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditEbookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editEbookMutation, { data, loading, error }] = useEditEbookMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditEbookMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditEbookMutation, Types.EditEbookMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.EditEbookMutation, Types.EditEbookMutationVariables>(EditEbookDocument, options);
}
export type EditEbookMutationHookResult = ReturnType<typeof useEditEbookMutation>;
export type EditEbookMutationResult = Apollo.MutationResult<Types.EditEbookMutation>;
export type EditEbookMutationOptions = Apollo.BaseMutationOptions<Types.EditEbookMutation, Types.EditEbookMutationVariables>;
export const EditPintoSubscriptionTitleDocument = gql`
  mutation EditPintoSubscriptionTitle($subscriptionTitleId: ID!, $input: EditPintoSubscriptionTitleInput!) {
    editPintoSubscriptionTitle(subscriptionTitleId: $subscriptionTitleId, input: $input) {
      _id
    }
  }
`;
export type EditPintoSubscriptionTitleMutationFn = Apollo.MutationFunction<Types.EditPintoSubscriptionTitleMutation, Types.EditPintoSubscriptionTitleMutationVariables>;

/**
 * __useEditPintoSubscriptionTitleMutation__
 *
 * To run a mutation, you first call `useEditPintoSubscriptionTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPintoSubscriptionTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPintoSubscriptionTitleMutation, { data, loading, error }] = useEditPintoSubscriptionTitleMutation({
 *   variables: {
 *      subscriptionTitleId: // value for 'subscriptionTitleId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditPintoSubscriptionTitleMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.EditPintoSubscriptionTitleMutation, Types.EditPintoSubscriptionTitleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.EditPintoSubscriptionTitleMutation, Types.EditPintoSubscriptionTitleMutationVariables>(EditPintoSubscriptionTitleDocument, options);
}
export type EditPintoSubscriptionTitleMutationHookResult = ReturnType<typeof useEditPintoSubscriptionTitleMutation>;
export type EditPintoSubscriptionTitleMutationResult = Apollo.MutationResult<Types.EditPintoSubscriptionTitleMutation>;
export type EditPintoSubscriptionTitleMutationOptions = Apollo.BaseMutationOptions<Types.EditPintoSubscriptionTitleMutation, Types.EditPintoSubscriptionTitleMutationVariables>;
export const LoginDocument = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      user {
        ...CurrentUser
      }
      accessToken
    }
  }
  ${CurrentUserFragmentDoc}
`;
export type LoginMutationFn = Apollo.MutationFunction<Types.LoginMutation, Types.LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<Types.LoginMutation, Types.LoginMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.LoginMutation, Types.LoginMutationVariables>(LoginDocument, options);
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<Types.LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<Types.LoginMutation, Types.LoginMutationVariables>;
export const PintoCancelEbookDiscountCampaignDocument = gql`
  mutation PintoCancelEbookDiscountCampaign($ebookDiscountCampaignId: ID!) {
    cancelEbookDiscountCampaign(ebookDiscountCampaignId: $ebookDiscountCampaignId)
  }
`;
export type PintoCancelEbookDiscountCampaignMutationFn = Apollo.MutationFunction<
  Types.PintoCancelEbookDiscountCampaignMutation,
  Types.PintoCancelEbookDiscountCampaignMutationVariables
>;

/**
 * __usePintoCancelEbookDiscountCampaignMutation__
 *
 * To run a mutation, you first call `usePintoCancelEbookDiscountCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePintoCancelEbookDiscountCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pintoCancelEbookDiscountCampaignMutation, { data, loading, error }] = usePintoCancelEbookDiscountCampaignMutation({
 *   variables: {
 *      ebookDiscountCampaignId: // value for 'ebookDiscountCampaignId'
 *   },
 * });
 */
export function usePintoCancelEbookDiscountCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.PintoCancelEbookDiscountCampaignMutation, Types.PintoCancelEbookDiscountCampaignMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.PintoCancelEbookDiscountCampaignMutation, Types.PintoCancelEbookDiscountCampaignMutationVariables>(
    PintoCancelEbookDiscountCampaignDocument,
    options,
  );
}
export type PintoCancelEbookDiscountCampaignMutationHookResult = ReturnType<typeof usePintoCancelEbookDiscountCampaignMutation>;
export type PintoCancelEbookDiscountCampaignMutationResult = Apollo.MutationResult<Types.PintoCancelEbookDiscountCampaignMutation>;
export type PintoCancelEbookDiscountCampaignMutationOptions = Apollo.BaseMutationOptions<
  Types.PintoCancelEbookDiscountCampaignMutation,
  Types.PintoCancelEbookDiscountCampaignMutationVariables
>;
export const PintoCreateEbookDiscountCampaignDocument = gql`
  mutation PintoCreateEbookDiscountCampaign($input: CreateEbookDiscountCampaignInput!) {
    createEbookDiscountCampaign(input: $input) {
      _id
      title
      status
      startedAt
      endedAt
      fileUrl
    }
  }
`;
export type PintoCreateEbookDiscountCampaignMutationFn = Apollo.MutationFunction<
  Types.PintoCreateEbookDiscountCampaignMutation,
  Types.PintoCreateEbookDiscountCampaignMutationVariables
>;

/**
 * __usePintoCreateEbookDiscountCampaignMutation__
 *
 * To run a mutation, you first call `usePintoCreateEbookDiscountCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePintoCreateEbookDiscountCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pintoCreateEbookDiscountCampaignMutation, { data, loading, error }] = usePintoCreateEbookDiscountCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePintoCreateEbookDiscountCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.PintoCreateEbookDiscountCampaignMutation, Types.PintoCreateEbookDiscountCampaignMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.PintoCreateEbookDiscountCampaignMutation, Types.PintoCreateEbookDiscountCampaignMutationVariables>(
    PintoCreateEbookDiscountCampaignDocument,
    options,
  );
}
export type PintoCreateEbookDiscountCampaignMutationHookResult = ReturnType<typeof usePintoCreateEbookDiscountCampaignMutation>;
export type PintoCreateEbookDiscountCampaignMutationResult = Apollo.MutationResult<Types.PintoCreateEbookDiscountCampaignMutation>;
export type PintoCreateEbookDiscountCampaignMutationOptions = Apollo.BaseMutationOptions<
  Types.PintoCreateEbookDiscountCampaignMutation,
  Types.PintoCreateEbookDiscountCampaignMutationVariables
>;
export const PintoCreateFeedDocument = gql`
  mutation PintoCreateFeed($input: CreateFeedInput!) {
    pintoCreateFeed(input: $input) {
      _id
    }
  }
`;
export type PintoCreateFeedMutationFn = Apollo.MutationFunction<Types.PintoCreateFeedMutation, Types.PintoCreateFeedMutationVariables>;

/**
 * __usePintoCreateFeedMutation__
 *
 * To run a mutation, you first call `usePintoCreateFeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePintoCreateFeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pintoCreateFeedMutation, { data, loading, error }] = usePintoCreateFeedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePintoCreateFeedMutation(baseOptions?: Apollo.MutationHookOptions<Types.PintoCreateFeedMutation, Types.PintoCreateFeedMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.PintoCreateFeedMutation, Types.PintoCreateFeedMutationVariables>(PintoCreateFeedDocument, options);
}
export type PintoCreateFeedMutationHookResult = ReturnType<typeof usePintoCreateFeedMutation>;
export type PintoCreateFeedMutationResult = Apollo.MutationResult<Types.PintoCreateFeedMutation>;
export type PintoCreateFeedMutationOptions = Apollo.BaseMutationOptions<Types.PintoCreateFeedMutation, Types.PintoCreateFeedMutationVariables>;
export const PintoCreatePresignedUploadEbookFileDocument = gql`
  mutation PintoCreatePresignedUploadEbookFile($input: CreatePresignedUploadEbookFileInput!) {
    pintoCreatePresignedUploadEbookFile(input: $input) {
      url
      fields
    }
  }
`;
export type PintoCreatePresignedUploadEbookFileMutationFn = Apollo.MutationFunction<
  Types.PintoCreatePresignedUploadEbookFileMutation,
  Types.PintoCreatePresignedUploadEbookFileMutationVariables
>;

/**
 * __usePintoCreatePresignedUploadEbookFileMutation__
 *
 * To run a mutation, you first call `usePintoCreatePresignedUploadEbookFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePintoCreatePresignedUploadEbookFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pintoCreatePresignedUploadEbookFileMutation, { data, loading, error }] = usePintoCreatePresignedUploadEbookFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePintoCreatePresignedUploadEbookFileMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.PintoCreatePresignedUploadEbookFileMutation, Types.PintoCreatePresignedUploadEbookFileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.PintoCreatePresignedUploadEbookFileMutation, Types.PintoCreatePresignedUploadEbookFileMutationVariables>(
    PintoCreatePresignedUploadEbookFileDocument,
    options,
  );
}
export type PintoCreatePresignedUploadEbookFileMutationHookResult = ReturnType<typeof usePintoCreatePresignedUploadEbookFileMutation>;
export type PintoCreatePresignedUploadEbookFileMutationResult = Apollo.MutationResult<Types.PintoCreatePresignedUploadEbookFileMutation>;
export type PintoCreatePresignedUploadEbookFileMutationOptions = Apollo.BaseMutationOptions<
  Types.PintoCreatePresignedUploadEbookFileMutation,
  Types.PintoCreatePresignedUploadEbookFileMutationVariables
>;
export const PintoCreateSectionDocument = gql`
  mutation PintoCreateSection($input: SectionInput!) {
    pintoCreateSection(input: $input) {
      ...Section
    }
  }
  ${SectionFragmentDoc}
`;
export type PintoCreateSectionMutationFn = Apollo.MutationFunction<Types.PintoCreateSectionMutation, Types.PintoCreateSectionMutationVariables>;

/**
 * __usePintoCreateSectionMutation__
 *
 * To run a mutation, you first call `usePintoCreateSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePintoCreateSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pintoCreateSectionMutation, { data, loading, error }] = usePintoCreateSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePintoCreateSectionMutation(baseOptions?: Apollo.MutationHookOptions<Types.PintoCreateSectionMutation, Types.PintoCreateSectionMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.PintoCreateSectionMutation, Types.PintoCreateSectionMutationVariables>(PintoCreateSectionDocument, options);
}
export type PintoCreateSectionMutationHookResult = ReturnType<typeof usePintoCreateSectionMutation>;
export type PintoCreateSectionMutationResult = Apollo.MutationResult<Types.PintoCreateSectionMutation>;
export type PintoCreateSectionMutationOptions = Apollo.BaseMutationOptions<Types.PintoCreateSectionMutation, Types.PintoCreateSectionMutationVariables>;
export const PintoDeleteFeedDocument = gql`
  mutation PintoDeleteFeed($slug: String!, $pref: FeedPref!) {
    pintoDeleteFeed(slug: $slug, pref: $pref)
  }
`;
export type PintoDeleteFeedMutationFn = Apollo.MutationFunction<Types.PintoDeleteFeedMutation, Types.PintoDeleteFeedMutationVariables>;

/**
 * __usePintoDeleteFeedMutation__
 *
 * To run a mutation, you first call `usePintoDeleteFeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePintoDeleteFeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pintoDeleteFeedMutation, { data, loading, error }] = usePintoDeleteFeedMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      pref: // value for 'pref'
 *   },
 * });
 */
export function usePintoDeleteFeedMutation(baseOptions?: Apollo.MutationHookOptions<Types.PintoDeleteFeedMutation, Types.PintoDeleteFeedMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.PintoDeleteFeedMutation, Types.PintoDeleteFeedMutationVariables>(PintoDeleteFeedDocument, options);
}
export type PintoDeleteFeedMutationHookResult = ReturnType<typeof usePintoDeleteFeedMutation>;
export type PintoDeleteFeedMutationResult = Apollo.MutationResult<Types.PintoDeleteFeedMutation>;
export type PintoDeleteFeedMutationOptions = Apollo.BaseMutationOptions<Types.PintoDeleteFeedMutation, Types.PintoDeleteFeedMutationVariables>;
export const PintoDeleteSectionDocument = gql`
  mutation PintoDeleteSection($id: ID!) {
    pintoDeleteSection(id: $id)
  }
`;
export type PintoDeleteSectionMutationFn = Apollo.MutationFunction<Types.PintoDeleteSectionMutation, Types.PintoDeleteSectionMutationVariables>;

/**
 * __usePintoDeleteSectionMutation__
 *
 * To run a mutation, you first call `usePintoDeleteSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePintoDeleteSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pintoDeleteSectionMutation, { data, loading, error }] = usePintoDeleteSectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePintoDeleteSectionMutation(baseOptions?: Apollo.MutationHookOptions<Types.PintoDeleteSectionMutation, Types.PintoDeleteSectionMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.PintoDeleteSectionMutation, Types.PintoDeleteSectionMutationVariables>(PintoDeleteSectionDocument, options);
}
export type PintoDeleteSectionMutationHookResult = ReturnType<typeof usePintoDeleteSectionMutation>;
export type PintoDeleteSectionMutationResult = Apollo.MutationResult<Types.PintoDeleteSectionMutation>;
export type PintoDeleteSectionMutationOptions = Apollo.BaseMutationOptions<Types.PintoDeleteSectionMutation, Types.PintoDeleteSectionMutationVariables>;
export const PintoDownloadEbookBundlesDocument = gql`
  mutation PintoDownloadEbookBundles {
    downloadEbookBundles
  }
`;
export type PintoDownloadEbookBundlesMutationFn = Apollo.MutationFunction<Types.PintoDownloadEbookBundlesMutation, Types.PintoDownloadEbookBundlesMutationVariables>;

/**
 * __usePintoDownloadEbookBundlesMutation__
 *
 * To run a mutation, you first call `usePintoDownloadEbookBundlesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePintoDownloadEbookBundlesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pintoDownloadEbookBundlesMutation, { data, loading, error }] = usePintoDownloadEbookBundlesMutation({
 *   variables: {
 *   },
 * });
 */
export function usePintoDownloadEbookBundlesMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.PintoDownloadEbookBundlesMutation, Types.PintoDownloadEbookBundlesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.PintoDownloadEbookBundlesMutation, Types.PintoDownloadEbookBundlesMutationVariables>(PintoDownloadEbookBundlesDocument, options);
}
export type PintoDownloadEbookBundlesMutationHookResult = ReturnType<typeof usePintoDownloadEbookBundlesMutation>;
export type PintoDownloadEbookBundlesMutationResult = Apollo.MutationResult<Types.PintoDownloadEbookBundlesMutation>;
export type PintoDownloadEbookBundlesMutationOptions = Apollo.BaseMutationOptions<Types.PintoDownloadEbookBundlesMutation, Types.PintoDownloadEbookBundlesMutationVariables>;
export const PintoHideEbookDocument = gql`
  mutation PintoHideEbook($ebookId: ID!) {
    hideEbook(ebookId: $ebookId) {
      _id
    }
  }
`;
export type PintoHideEbookMutationFn = Apollo.MutationFunction<Types.PintoHideEbookMutation, Types.PintoHideEbookMutationVariables>;

/**
 * __usePintoHideEbookMutation__
 *
 * To run a mutation, you first call `usePintoHideEbookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePintoHideEbookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pintoHideEbookMutation, { data, loading, error }] = usePintoHideEbookMutation({
 *   variables: {
 *      ebookId: // value for 'ebookId'
 *   },
 * });
 */
export function usePintoHideEbookMutation(baseOptions?: Apollo.MutationHookOptions<Types.PintoHideEbookMutation, Types.PintoHideEbookMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.PintoHideEbookMutation, Types.PintoHideEbookMutationVariables>(PintoHideEbookDocument, options);
}
export type PintoHideEbookMutationHookResult = ReturnType<typeof usePintoHideEbookMutation>;
export type PintoHideEbookMutationResult = Apollo.MutationResult<Types.PintoHideEbookMutation>;
export type PintoHideEbookMutationOptions = Apollo.BaseMutationOptions<Types.PintoHideEbookMutation, Types.PintoHideEbookMutationVariables>;
export const PintoRejectEditEbookDocument = gql`
  mutation PintoRejectEditEbook($ebookId: ID!, $rejectNote: String, $fileTypes: [EbookFileType!]!) {
    rejectEditEbook(ebookId: $ebookId, rejectNote: $rejectNote, fileTypes: $fileTypes) {
      message
      success
    }
  }
`;
export type PintoRejectEditEbookMutationFn = Apollo.MutationFunction<Types.PintoRejectEditEbookMutation, Types.PintoRejectEditEbookMutationVariables>;

/**
 * __usePintoRejectEditEbookMutation__
 *
 * To run a mutation, you first call `usePintoRejectEditEbookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePintoRejectEditEbookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pintoRejectEditEbookMutation, { data, loading, error }] = usePintoRejectEditEbookMutation({
 *   variables: {
 *      ebookId: // value for 'ebookId'
 *      rejectNote: // value for 'rejectNote'
 *      fileTypes: // value for 'fileTypes'
 *   },
 * });
 */
export function usePintoRejectEditEbookMutation(baseOptions?: Apollo.MutationHookOptions<Types.PintoRejectEditEbookMutation, Types.PintoRejectEditEbookMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.PintoRejectEditEbookMutation, Types.PintoRejectEditEbookMutationVariables>(PintoRejectEditEbookDocument, options);
}
export type PintoRejectEditEbookMutationHookResult = ReturnType<typeof usePintoRejectEditEbookMutation>;
export type PintoRejectEditEbookMutationResult = Apollo.MutationResult<Types.PintoRejectEditEbookMutation>;
export type PintoRejectEditEbookMutationOptions = Apollo.BaseMutationOptions<Types.PintoRejectEditEbookMutation, Types.PintoRejectEditEbookMutationVariables>;
export const PintoRemoveEbookDocument = gql`
  mutation PintoRemoveEbook($input: RemoveEbookInput!) {
    removeEbook(input: $input) {
      _id
    }
  }
`;
export type PintoRemoveEbookMutationFn = Apollo.MutationFunction<Types.PintoRemoveEbookMutation, Types.PintoRemoveEbookMutationVariables>;

/**
 * __usePintoRemoveEbookMutation__
 *
 * To run a mutation, you first call `usePintoRemoveEbookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePintoRemoveEbookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pintoRemoveEbookMutation, { data, loading, error }] = usePintoRemoveEbookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePintoRemoveEbookMutation(baseOptions?: Apollo.MutationHookOptions<Types.PintoRemoveEbookMutation, Types.PintoRemoveEbookMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.PintoRemoveEbookMutation, Types.PintoRemoveEbookMutationVariables>(PintoRemoveEbookDocument, options);
}
export type PintoRemoveEbookMutationHookResult = ReturnType<typeof usePintoRemoveEbookMutation>;
export type PintoRemoveEbookMutationResult = Apollo.MutationResult<Types.PintoRemoveEbookMutation>;
export type PintoRemoveEbookMutationOptions = Apollo.BaseMutationOptions<Types.PintoRemoveEbookMutation, Types.PintoRemoveEbookMutationVariables>;
export const PintoRemoveEbookBundleDocument = gql`
  mutation PintoRemoveEbookBundle($input: RemoveEbookBundleInput!) {
    removeEbookBundle(input: $input) {
      _id
    }
  }
`;
export type PintoRemoveEbookBundleMutationFn = Apollo.MutationFunction<Types.PintoRemoveEbookBundleMutation, Types.PintoRemoveEbookBundleMutationVariables>;

/**
 * __usePintoRemoveEbookBundleMutation__
 *
 * To run a mutation, you first call `usePintoRemoveEbookBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePintoRemoveEbookBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pintoRemoveEbookBundleMutation, { data, loading, error }] = usePintoRemoveEbookBundleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePintoRemoveEbookBundleMutation(baseOptions?: Apollo.MutationHookOptions<Types.PintoRemoveEbookBundleMutation, Types.PintoRemoveEbookBundleMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.PintoRemoveEbookBundleMutation, Types.PintoRemoveEbookBundleMutationVariables>(PintoRemoveEbookBundleDocument, options);
}
export type PintoRemoveEbookBundleMutationHookResult = ReturnType<typeof usePintoRemoveEbookBundleMutation>;
export type PintoRemoveEbookBundleMutationResult = Apollo.MutationResult<Types.PintoRemoveEbookBundleMutation>;
export type PintoRemoveEbookBundleMutationOptions = Apollo.BaseMutationOptions<Types.PintoRemoveEbookBundleMutation, Types.PintoRemoveEbookBundleMutationVariables>;
export const PintoUnHideEbookDocument = gql`
  mutation PintoUnHideEbook($ebookId: ID!) {
    unhideEbook(ebookId: $ebookId) {
      _id
    }
  }
`;
export type PintoUnHideEbookMutationFn = Apollo.MutationFunction<Types.PintoUnHideEbookMutation, Types.PintoUnHideEbookMutationVariables>;

/**
 * __usePintoUnHideEbookMutation__
 *
 * To run a mutation, you first call `usePintoUnHideEbookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePintoUnHideEbookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pintoUnHideEbookMutation, { data, loading, error }] = usePintoUnHideEbookMutation({
 *   variables: {
 *      ebookId: // value for 'ebookId'
 *   },
 * });
 */
export function usePintoUnHideEbookMutation(baseOptions?: Apollo.MutationHookOptions<Types.PintoUnHideEbookMutation, Types.PintoUnHideEbookMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.PintoUnHideEbookMutation, Types.PintoUnHideEbookMutationVariables>(PintoUnHideEbookDocument, options);
}
export type PintoUnHideEbookMutationHookResult = ReturnType<typeof usePintoUnHideEbookMutation>;
export type PintoUnHideEbookMutationResult = Apollo.MutationResult<Types.PintoUnHideEbookMutation>;
export type PintoUnHideEbookMutationOptions = Apollo.BaseMutationOptions<Types.PintoUnHideEbookMutation, Types.PintoUnHideEbookMutationVariables>;
export const PintoUpdateFeedDocument = gql`
  mutation PintoUpdateFeed($slug: String!, $pref: FeedPref!, $input: FeedInput!) {
    pintoUpdateFeed(slug: $slug, pref: $pref, input: $input) {
      _id
    }
  }
`;
export type PintoUpdateFeedMutationFn = Apollo.MutationFunction<Types.PintoUpdateFeedMutation, Types.PintoUpdateFeedMutationVariables>;

/**
 * __usePintoUpdateFeedMutation__
 *
 * To run a mutation, you first call `usePintoUpdateFeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePintoUpdateFeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pintoUpdateFeedMutation, { data, loading, error }] = usePintoUpdateFeedMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      pref: // value for 'pref'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePintoUpdateFeedMutation(baseOptions?: Apollo.MutationHookOptions<Types.PintoUpdateFeedMutation, Types.PintoUpdateFeedMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.PintoUpdateFeedMutation, Types.PintoUpdateFeedMutationVariables>(PintoUpdateFeedDocument, options);
}
export type PintoUpdateFeedMutationHookResult = ReturnType<typeof usePintoUpdateFeedMutation>;
export type PintoUpdateFeedMutationResult = Apollo.MutationResult<Types.PintoUpdateFeedMutation>;
export type PintoUpdateFeedMutationOptions = Apollo.BaseMutationOptions<Types.PintoUpdateFeedMutation, Types.PintoUpdateFeedMutationVariables>;
export const PintoUpdateSectionDocument = gql`
  mutation PintoUpdateSection($id: ID!, $input: SectionInput!) {
    pintoUpdateSection(id: $id, input: $input) {
      ...Section
    }
  }
  ${SectionFragmentDoc}
`;
export type PintoUpdateSectionMutationFn = Apollo.MutationFunction<Types.PintoUpdateSectionMutation, Types.PintoUpdateSectionMutationVariables>;

/**
 * __usePintoUpdateSectionMutation__
 *
 * To run a mutation, you first call `usePintoUpdateSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePintoUpdateSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pintoUpdateSectionMutation, { data, loading, error }] = usePintoUpdateSectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePintoUpdateSectionMutation(baseOptions?: Apollo.MutationHookOptions<Types.PintoUpdateSectionMutation, Types.PintoUpdateSectionMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.PintoUpdateSectionMutation, Types.PintoUpdateSectionMutationVariables>(PintoUpdateSectionDocument, options);
}
export type PintoUpdateSectionMutationHookResult = ReturnType<typeof usePintoUpdateSectionMutation>;
export type PintoUpdateSectionMutationResult = Apollo.MutationResult<Types.PintoUpdateSectionMutation>;
export type PintoUpdateSectionMutationOptions = Apollo.BaseMutationOptions<Types.PintoUpdateSectionMutation, Types.PintoUpdateSectionMutationVariables>;
export const PintoUploadEbookBundleDocument = gql`
  mutation PintoUploadEbookBundle($input: UploadEbookBundleInput!) {
    uploadEbookBundle(input: $input)
  }
`;
export type PintoUploadEbookBundleMutationFn = Apollo.MutationFunction<Types.PintoUploadEbookBundleMutation, Types.PintoUploadEbookBundleMutationVariables>;

/**
 * __usePintoUploadEbookBundleMutation__
 *
 * To run a mutation, you first call `usePintoUploadEbookBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePintoUploadEbookBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pintoUploadEbookBundleMutation, { data, loading, error }] = usePintoUploadEbookBundleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePintoUploadEbookBundleMutation(baseOptions?: Apollo.MutationHookOptions<Types.PintoUploadEbookBundleMutation, Types.PintoUploadEbookBundleMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.PintoUploadEbookBundleMutation, Types.PintoUploadEbookBundleMutationVariables>(PintoUploadEbookBundleDocument, options);
}
export type PintoUploadEbookBundleMutationHookResult = ReturnType<typeof usePintoUploadEbookBundleMutation>;
export type PintoUploadEbookBundleMutationResult = Apollo.MutationResult<Types.PintoUploadEbookBundleMutation>;
export type PintoUploadEbookBundleMutationOptions = Apollo.BaseMutationOptions<Types.PintoUploadEbookBundleMutation, Types.PintoUploadEbookBundleMutationVariables>;
export const PintoUploadEbookImageDocument = gql`
  mutation PintoUploadEbookImage($input: UploadEbookImageInput!) {
    pintoUploadEbookImage(input: $input)
  }
`;
export type PintoUploadEbookImageMutationFn = Apollo.MutationFunction<Types.PintoUploadEbookImageMutation, Types.PintoUploadEbookImageMutationVariables>;

/**
 * __usePintoUploadEbookImageMutation__
 *
 * To run a mutation, you first call `usePintoUploadEbookImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePintoUploadEbookImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pintoUploadEbookImageMutation, { data, loading, error }] = usePintoUploadEbookImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePintoUploadEbookImageMutation(baseOptions?: Apollo.MutationHookOptions<Types.PintoUploadEbookImageMutation, Types.PintoUploadEbookImageMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.PintoUploadEbookImageMutation, Types.PintoUploadEbookImageMutationVariables>(PintoUploadEbookImageDocument, options);
}
export type PintoUploadEbookImageMutationHookResult = ReturnType<typeof usePintoUploadEbookImageMutation>;
export type PintoUploadEbookImageMutationResult = Apollo.MutationResult<Types.PintoUploadEbookImageMutation>;
export type PintoUploadEbookImageMutationOptions = Apollo.BaseMutationOptions<Types.PintoUploadEbookImageMutation, Types.PintoUploadEbookImageMutationVariables>;
export const PintoUploadRequestedEbookFileDocument = gql`
  mutation PintoUploadRequestedEbookFile($ebookId: ID!, $input: UploadRequestedEbookFileInput!) {
    uploadRequestedEbookFile(ebookId: $ebookId, input: $input) {
      message
      success
    }
  }
`;
export type PintoUploadRequestedEbookFileMutationFn = Apollo.MutationFunction<Types.PintoUploadRequestedEbookFileMutation, Types.PintoUploadRequestedEbookFileMutationVariables>;

/**
 * __usePintoUploadRequestedEbookFileMutation__
 *
 * To run a mutation, you first call `usePintoUploadRequestedEbookFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePintoUploadRequestedEbookFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pintoUploadRequestedEbookFileMutation, { data, loading, error }] = usePintoUploadRequestedEbookFileMutation({
 *   variables: {
 *      ebookId: // value for 'ebookId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePintoUploadRequestedEbookFileMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.PintoUploadRequestedEbookFileMutation, Types.PintoUploadRequestedEbookFileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.PintoUploadRequestedEbookFileMutation, Types.PintoUploadRequestedEbookFileMutationVariables>(PintoUploadRequestedEbookFileDocument, options);
}
export type PintoUploadRequestedEbookFileMutationHookResult = ReturnType<typeof usePintoUploadRequestedEbookFileMutation>;
export type PintoUploadRequestedEbookFileMutationResult = Apollo.MutationResult<Types.PintoUploadRequestedEbookFileMutation>;
export type PintoUploadRequestedEbookFileMutationOptions = Apollo.BaseMutationOptions<
  Types.PintoUploadRequestedEbookFileMutation,
  Types.PintoUploadRequestedEbookFileMutationVariables
>;
export const PintoVouchersDeleteDocument = gql`
  mutation PintoVouchersDelete($codes: [ID!]!) {
    deletePintoVouchers(codes: $codes) {
      message
      success
    }
  }
`;
export type PintoVouchersDeleteMutationFn = Apollo.MutationFunction<Types.PintoVouchersDeleteMutation, Types.PintoVouchersDeleteMutationVariables>;

/**
 * __usePintoVouchersDeleteMutation__
 *
 * To run a mutation, you first call `usePintoVouchersDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePintoVouchersDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pintoVouchersDeleteMutation, { data, loading, error }] = usePintoVouchersDeleteMutation({
 *   variables: {
 *      codes: // value for 'codes'
 *   },
 * });
 */
export function usePintoVouchersDeleteMutation(baseOptions?: Apollo.MutationHookOptions<Types.PintoVouchersDeleteMutation, Types.PintoVouchersDeleteMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.PintoVouchersDeleteMutation, Types.PintoVouchersDeleteMutationVariables>(PintoVouchersDeleteDocument, options);
}
export type PintoVouchersDeleteMutationHookResult = ReturnType<typeof usePintoVouchersDeleteMutation>;
export type PintoVouchersDeleteMutationResult = Apollo.MutationResult<Types.PintoVouchersDeleteMutation>;
export type PintoVouchersDeleteMutationOptions = Apollo.BaseMutationOptions<Types.PintoVouchersDeleteMutation, Types.PintoVouchersDeleteMutationVariables>;
export const PintoVouchersUpdateDocument = gql`
  mutation PintoVouchersUpdate($codes: [ID!]!, $input: UpdatePintoVouchersInput!) {
    updatePintoVouchers(codes: $codes, input: $input) {
      message
      success
    }
  }
`;
export type PintoVouchersUpdateMutationFn = Apollo.MutationFunction<Types.PintoVouchersUpdateMutation, Types.PintoVouchersUpdateMutationVariables>;

/**
 * __usePintoVouchersUpdateMutation__
 *
 * To run a mutation, you first call `usePintoVouchersUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePintoVouchersUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pintoVouchersUpdateMutation, { data, loading, error }] = usePintoVouchersUpdateMutation({
 *   variables: {
 *      codes: // value for 'codes'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePintoVouchersUpdateMutation(baseOptions?: Apollo.MutationHookOptions<Types.PintoVouchersUpdateMutation, Types.PintoVouchersUpdateMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.PintoVouchersUpdateMutation, Types.PintoVouchersUpdateMutationVariables>(PintoVouchersUpdateDocument, options);
}
export type PintoVouchersUpdateMutationHookResult = ReturnType<typeof usePintoVouchersUpdateMutation>;
export type PintoVouchersUpdateMutationResult = Apollo.MutationResult<Types.PintoVouchersUpdateMutation>;
export type PintoVouchersUpdateMutationOptions = Apollo.BaseMutationOptions<Types.PintoVouchersUpdateMutation, Types.PintoVouchersUpdateMutationVariables>;
export const PintoVouchersUploadDocument = gql`
  mutation PintoVouchersUpload($input: UploadPintoVouchersInput!) {
    uploadPintoVouchers(input: $input) {
      valid
      errors {
        code
        errors
      }
    }
  }
`;
export type PintoVouchersUploadMutationFn = Apollo.MutationFunction<Types.PintoVouchersUploadMutation, Types.PintoVouchersUploadMutationVariables>;

/**
 * __usePintoVouchersUploadMutation__
 *
 * To run a mutation, you first call `usePintoVouchersUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePintoVouchersUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pintoVouchersUploadMutation, { data, loading, error }] = usePintoVouchersUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePintoVouchersUploadMutation(baseOptions?: Apollo.MutationHookOptions<Types.PintoVouchersUploadMutation, Types.PintoVouchersUploadMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.PintoVouchersUploadMutation, Types.PintoVouchersUploadMutationVariables>(PintoVouchersUploadDocument, options);
}
export type PintoVouchersUploadMutationHookResult = ReturnType<typeof usePintoVouchersUploadMutation>;
export type PintoVouchersUploadMutationResult = Apollo.MutationResult<Types.PintoVouchersUploadMutation>;
export type PintoVouchersUploadMutationOptions = Apollo.BaseMutationOptions<Types.PintoVouchersUploadMutation, Types.PintoVouchersUploadMutationVariables>;
export const SignedUrlDocument = gql`
  mutation SignedUrl($url: String!) {
    signedUrl(url: $url)
  }
`;
export type SignedUrlMutationFn = Apollo.MutationFunction<Types.SignedUrlMutation, Types.SignedUrlMutationVariables>;

/**
 * __useSignedUrlMutation__
 *
 * To run a mutation, you first call `useSignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signedUrlMutation, { data, loading, error }] = useSignedUrlMutation({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useSignedUrlMutation(baseOptions?: Apollo.MutationHookOptions<Types.SignedUrlMutation, Types.SignedUrlMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.SignedUrlMutation, Types.SignedUrlMutationVariables>(SignedUrlDocument, options);
}
export type SignedUrlMutationHookResult = ReturnType<typeof useSignedUrlMutation>;
export type SignedUrlMutationResult = Apollo.MutationResult<Types.SignedUrlMutation>;
export type SignedUrlMutationOptions = Apollo.BaseMutationOptions<Types.SignedUrlMutation, Types.SignedUrlMutationVariables>;
export const SubmitReviewDraftEbookDocument = gql`
  mutation SubmitReviewDraftEbook($ebookId: ID!, $input: SubmitReviewEbookInput!) {
    submitReviewDraftEbook(ebookId: $ebookId, input: $input) {
      ...EbookDetail
    }
  }
  ${EbookDetailFragmentDoc}
`;
export type SubmitReviewDraftEbookMutationFn = Apollo.MutationFunction<Types.SubmitReviewDraftEbookMutation, Types.SubmitReviewDraftEbookMutationVariables>;

/**
 * __useSubmitReviewDraftEbookMutation__
 *
 * To run a mutation, you first call `useSubmitReviewDraftEbookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitReviewDraftEbookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitReviewDraftEbookMutation, { data, loading, error }] = useSubmitReviewDraftEbookMutation({
 *   variables: {
 *      ebookId: // value for 'ebookId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitReviewDraftEbookMutation(baseOptions?: Apollo.MutationHookOptions<Types.SubmitReviewDraftEbookMutation, Types.SubmitReviewDraftEbookMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.SubmitReviewDraftEbookMutation, Types.SubmitReviewDraftEbookMutationVariables>(SubmitReviewDraftEbookDocument, options);
}
export type SubmitReviewDraftEbookMutationHookResult = ReturnType<typeof useSubmitReviewDraftEbookMutation>;
export type SubmitReviewDraftEbookMutationResult = Apollo.MutationResult<Types.SubmitReviewDraftEbookMutation>;
export type SubmitReviewDraftEbookMutationOptions = Apollo.BaseMutationOptions<Types.SubmitReviewDraftEbookMutation, Types.SubmitReviewDraftEbookMutationVariables>;
export const UpdatePopupDocument = gql`
  mutation UpdatePopup($popupId: ID!, $input: UpdatePopupInput!) {
    updatePopup(popupId: $popupId, input: $input) {
      ...PopupNode
    }
  }
  ${PopupNodeFragmentDoc}
`;
export type UpdatePopupMutationFn = Apollo.MutationFunction<Types.UpdatePopupMutation, Types.UpdatePopupMutationVariables>;

/**
 * __useUpdatePopupMutation__
 *
 * To run a mutation, you first call `useUpdatePopupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePopupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePopupMutation, { data, loading, error }] = useUpdatePopupMutation({
 *   variables: {
 *      popupId: // value for 'popupId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePopupMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePopupMutation, Types.UpdatePopupMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdatePopupMutation, Types.UpdatePopupMutationVariables>(UpdatePopupDocument, options);
}
export type UpdatePopupMutationHookResult = ReturnType<typeof useUpdatePopupMutation>;
export type UpdatePopupMutationResult = Apollo.MutationResult<Types.UpdatePopupMutation>;
export type UpdatePopupMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePopupMutation, Types.UpdatePopupMutationVariables>;
export const UpdateTemplateDocument = gql`
  mutation UpdateTemplate($templateId: ID!, $input: UpdateTemplateInput!) {
    updateTemplate(templateId: $templateId, input: $input) {
      ...Template
    }
  }
  ${TemplateFragmentDoc}
`;
export type UpdateTemplateMutationFn = Apollo.MutationFunction<Types.UpdateTemplateMutation, Types.UpdateTemplateMutationVariables>;

/**
 * __useUpdateTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplateMutation, { data, loading, error }] = useUpdateTemplateMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTemplateMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateTemplateMutation, Types.UpdateTemplateMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateTemplateMutation, Types.UpdateTemplateMutationVariables>(UpdateTemplateDocument, options);
}
export type UpdateTemplateMutationHookResult = ReturnType<typeof useUpdateTemplateMutation>;
export type UpdateTemplateMutationResult = Apollo.MutationResult<Types.UpdateTemplateMutation>;
export type UpdateTemplateMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTemplateMutation, Types.UpdateTemplateMutationVariables>;
export const UploadStaticFileDocument = gql`
  mutation uploadStaticFile($data: String!) {
    uploadStaticFile(data: $data) {
      url
    }
  }
`;
export type UploadStaticFileMutationFn = Apollo.MutationFunction<Types.UploadStaticFileMutation, Types.UploadStaticFileMutationVariables>;

/**
 * __useUploadStaticFileMutation__
 *
 * To run a mutation, you first call `useUploadStaticFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadStaticFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadStaticFileMutation, { data, loading, error }] = useUploadStaticFileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUploadStaticFileMutation(baseOptions?: Apollo.MutationHookOptions<Types.UploadStaticFileMutation, Types.UploadStaticFileMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UploadStaticFileMutation, Types.UploadStaticFileMutationVariables>(UploadStaticFileDocument, options);
}
export type UploadStaticFileMutationHookResult = ReturnType<typeof useUploadStaticFileMutation>;
export type UploadStaticFileMutationResult = Apollo.MutationResult<Types.UploadStaticFileMutation>;
export type UploadStaticFileMutationOptions = Apollo.BaseMutationOptions<Types.UploadStaticFileMutation, Types.UploadStaticFileMutationVariables>;
export const UpsertTemplateDocument = gql`
  mutation UpsertTemplate($input: UpsertTemplateInput!) {
    upsertTemplate(input: $input) {
      ...Template
    }
  }
  ${TemplateFragmentDoc}
`;
export type UpsertTemplateMutationFn = Apollo.MutationFunction<Types.UpsertTemplateMutation, Types.UpsertTemplateMutationVariables>;

/**
 * __useUpsertTemplateMutation__
 *
 * To run a mutation, you first call `useUpsertTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertTemplateMutation, { data, loading, error }] = useUpsertTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertTemplateMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpsertTemplateMutation, Types.UpsertTemplateMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpsertTemplateMutation, Types.UpsertTemplateMutationVariables>(UpsertTemplateDocument, options);
}
export type UpsertTemplateMutationHookResult = ReturnType<typeof useUpsertTemplateMutation>;
export type UpsertTemplateMutationResult = Apollo.MutationResult<Types.UpsertTemplateMutation>;
export type UpsertTemplateMutationOptions = Apollo.BaseMutationOptions<Types.UpsertTemplateMutation, Types.UpsertTemplateMutationVariables>;
export const SearchSubscriptionTitlesDocument = gql`
  query SearchSubscriptionTitles($filter: SearchSubscriptionTitlesFilter!, $includeDetail: Boolean!) {
    searchSubscriptionTitles(filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          _id
          title
          ...SubscriptionTitleDetail @include(if: $includeDetail)
        }
      }
    }
  }
  ${SubscriptionTitleDetailFragmentDoc}
`;

/**
 * __useSearchSubscriptionTitlesQuery__
 *
 * To run a query within a React component, call `useSearchSubscriptionTitlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSubscriptionTitlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSubscriptionTitlesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      includeDetail: // value for 'includeDetail'
 *   },
 * });
 */
export function useSearchSubscriptionTitlesQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchSubscriptionTitlesQuery, Types.SearchSubscriptionTitlesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.SearchSubscriptionTitlesQuery, Types.SearchSubscriptionTitlesQueryVariables>(SearchSubscriptionTitlesDocument, options);
}
export function useSearchSubscriptionTitlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchSubscriptionTitlesQuery, Types.SearchSubscriptionTitlesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.SearchSubscriptionTitlesQuery, Types.SearchSubscriptionTitlesQueryVariables>(SearchSubscriptionTitlesDocument, options);
}
export type SearchSubscriptionTitlesQueryHookResult = ReturnType<typeof useSearchSubscriptionTitlesQuery>;
export type SearchSubscriptionTitlesLazyQueryHookResult = ReturnType<typeof useSearchSubscriptionTitlesLazyQuery>;
export type SearchSubscriptionTitlesQueryResult = Apollo.QueryResult<Types.SearchSubscriptionTitlesQuery, Types.SearchSubscriptionTitlesQueryVariables>;
export const AllCategoriesDocument = gql`
  query AllCategories {
    fictionCategories: categories(filter: { contentType: fiction }) {
      _id
      name
    }
    comicCategories: categories(filter: { contentType: comic }) {
      _id
      name
    }
    nonfictionCategories: categories(filter: { contentType: nonfiction }) {
      _id
      name
    }
    fictionContentRatings: contentRatings(filter: { contentType: fiction }) {
      _id
      name
    }
    comicContentRatings: contentRatings(filter: { contentType: comic }) {
      _id
      name
    }
    nonfictionContentRatings: contentRatings(filter: { contentType: nonfiction }) {
      _id
      name
    }
  }
`;

/**
 * __useAllCategoriesQuery__
 *
 * To run a query within a React component, call `useAllCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllCategoriesQuery, Types.AllCategoriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.AllCategoriesQuery, Types.AllCategoriesQueryVariables>(AllCategoriesDocument, options);
}
export function useAllCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllCategoriesQuery, Types.AllCategoriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.AllCategoriesQuery, Types.AllCategoriesQueryVariables>(AllCategoriesDocument, options);
}
export type AllCategoriesQueryHookResult = ReturnType<typeof useAllCategoriesQuery>;
export type AllCategoriesLazyQueryHookResult = ReturnType<typeof useAllCategoriesLazyQuery>;
export type AllCategoriesQueryResult = Apollo.QueryResult<Types.AllCategoriesQuery, Types.AllCategoriesQueryVariables>;
export const BookPageAdsDocument = gql`
  query BookPageAds($filter: BookPageAdsFilter) {
    bookPageAds(filter: $filter) {
      totalCount
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          ...BookPageAd
          chapterPageAds {
            chapterId
            title
          }
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${BookPageAdFragmentDoc}
`;

/**
 * __useBookPageAdsQuery__
 *
 * To run a query within a React component, call `useBookPageAdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookPageAdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookPageAdsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useBookPageAdsQuery(baseOptions?: Apollo.QueryHookOptions<Types.BookPageAdsQuery, Types.BookPageAdsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.BookPageAdsQuery, Types.BookPageAdsQueryVariables>(BookPageAdsDocument, options);
}
export function useBookPageAdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BookPageAdsQuery, Types.BookPageAdsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.BookPageAdsQuery, Types.BookPageAdsQueryVariables>(BookPageAdsDocument, options);
}
export type BookPageAdsQueryHookResult = ReturnType<typeof useBookPageAdsQuery>;
export type BookPageAdsLazyQueryHookResult = ReturnType<typeof useBookPageAdsLazyQuery>;
export type BookPageAdsQueryResult = Apollo.QueryResult<Types.BookPageAdsQuery, Types.BookPageAdsQueryVariables>;
export const CurrentUserDocument = gql`
  query CurrentUser {
    currentUser {
      ...CurrentUser
    }
  }
  ${CurrentUserFragmentDoc}
`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>(CurrentUserDocument, options);
}
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>(CurrentUserDocument, options);
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>;
export const EbookOptionsDocument = gql`
  query EbookOptions {
    languageOptions: ebookOptions {
      languages {
        ...Language
      }
    }
    applePriceTierOptions: ebookOptions(filter: { platform: apple }) {
      priceTiers {
        ...PriceTier
      }
    }
    googlePriceTierOptions: ebookOptions(filter: { platform: google }) {
      priceTiers {
        ...PriceTier
      }
    }
  }
  ${LanguageFragmentDoc}
  ${PriceTierFragmentDoc}
`;

/**
 * __useEbookOptionsQuery__
 *
 * To run a query within a React component, call `useEbookOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEbookOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEbookOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEbookOptionsQuery(baseOptions?: Apollo.QueryHookOptions<Types.EbookOptionsQuery, Types.EbookOptionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.EbookOptionsQuery, Types.EbookOptionsQueryVariables>(EbookOptionsDocument, options);
}
export function useEbookOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EbookOptionsQuery, Types.EbookOptionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.EbookOptionsQuery, Types.EbookOptionsQueryVariables>(EbookOptionsDocument, options);
}
export type EbookOptionsQueryHookResult = ReturnType<typeof useEbookOptionsQuery>;
export type EbookOptionsLazyQueryHookResult = ReturnType<typeof useEbookOptionsLazyQuery>;
export type EbookOptionsQueryResult = Apollo.QueryResult<Types.EbookOptionsQuery, Types.EbookOptionsQueryVariables>;
export const PendingEbookInSubscriptionTitleDocument = gql`
  query PendingEbookInSubscriptionTitle($subscriptionTitleId: ID!) {
    pendingEbookInSubscriptionTitle(subscriptionTitleId: $subscriptionTitleId) {
      ebookTitle
      ebookStatus
    }
  }
`;

/**
 * __usePendingEbookInSubscriptionTitleQuery__
 *
 * To run a query within a React component, call `usePendingEbookInSubscriptionTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingEbookInSubscriptionTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingEbookInSubscriptionTitleQuery({
 *   variables: {
 *      subscriptionTitleId: // value for 'subscriptionTitleId'
 *   },
 * });
 */
export function usePendingEbookInSubscriptionTitleQuery(
  baseOptions: Apollo.QueryHookOptions<Types.PendingEbookInSubscriptionTitleQuery, Types.PendingEbookInSubscriptionTitleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.PendingEbookInSubscriptionTitleQuery, Types.PendingEbookInSubscriptionTitleQueryVariables>(PendingEbookInSubscriptionTitleDocument, options);
}
export function usePendingEbookInSubscriptionTitleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.PendingEbookInSubscriptionTitleQuery, Types.PendingEbookInSubscriptionTitleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.PendingEbookInSubscriptionTitleQuery, Types.PendingEbookInSubscriptionTitleQueryVariables>(PendingEbookInSubscriptionTitleDocument, options);
}
export type PendingEbookInSubscriptionTitleQueryHookResult = ReturnType<typeof usePendingEbookInSubscriptionTitleQuery>;
export type PendingEbookInSubscriptionTitleLazyQueryHookResult = ReturnType<typeof usePendingEbookInSubscriptionTitleLazyQuery>;
export type PendingEbookInSubscriptionTitleQueryResult = Apollo.QueryResult<Types.PendingEbookInSubscriptionTitleQuery, Types.PendingEbookInSubscriptionTitleQueryVariables>;
export const PintoCategoriesDocument = gql`
  query PintoCategories {
    pintoCategories(filter: {}) {
      ...PintoCategory
      childrens {
        ...PintoCategory
        parentId
        parent {
          ...PintoCategory
          __typename
        }
        childrens {
          ...PintoCategory
          parentId
          parent {
            ...PintoCategory
            __typename
          }
          childrens {
            ...PintoCategory
            parentId
            parent {
              ...PintoCategory
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
  ${PintoCategoryFragmentDoc}
`;

/**
 * __usePintoCategoriesQuery__
 *
 * To run a query within a React component, call `usePintoCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePintoCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePintoCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePintoCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<Types.PintoCategoriesQuery, Types.PintoCategoriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.PintoCategoriesQuery, Types.PintoCategoriesQueryVariables>(PintoCategoriesDocument, options);
}
export function usePintoCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PintoCategoriesQuery, Types.PintoCategoriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.PintoCategoriesQuery, Types.PintoCategoriesQueryVariables>(PintoCategoriesDocument, options);
}
export type PintoCategoriesQueryHookResult = ReturnType<typeof usePintoCategoriesQuery>;
export type PintoCategoriesLazyQueryHookResult = ReturnType<typeof usePintoCategoriesLazyQuery>;
export type PintoCategoriesQueryResult = Apollo.QueryResult<Types.PintoCategoriesQuery, Types.PintoCategoriesQueryVariables>;
export const PintoEbookDocument = gql`
  query PintoEbook($id: ID!) {
    ebook(id: $id) {
      ...EbookDetail
    }
  }
  ${EbookDetailFragmentDoc}
`;

/**
 * __usePintoEbookQuery__
 *
 * To run a query within a React component, call `usePintoEbookQuery` and pass it any options that fit your needs.
 * When your component renders, `usePintoEbookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePintoEbookQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePintoEbookQuery(baseOptions: Apollo.QueryHookOptions<Types.PintoEbookQuery, Types.PintoEbookQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.PintoEbookQuery, Types.PintoEbookQueryVariables>(PintoEbookDocument, options);
}
export function usePintoEbookLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PintoEbookQuery, Types.PintoEbookQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.PintoEbookQuery, Types.PintoEbookQueryVariables>(PintoEbookDocument, options);
}
export type PintoEbookQueryHookResult = ReturnType<typeof usePintoEbookQuery>;
export type PintoEbookLazyQueryHookResult = ReturnType<typeof usePintoEbookLazyQuery>;
export type PintoEbookQueryResult = Apollo.QueryResult<Types.PintoEbookQuery, Types.PintoEbookQueryVariables>;
export const PintoEbookBundleDocument = gql`
  query PintoEbookBundle($id: ID!) {
    ebookBundle(id: $id) {
      ...EbookBundle
    }
  }
  ${EbookBundleFragmentDoc}
`;

/**
 * __usePintoEbookBundleQuery__
 *
 * To run a query within a React component, call `usePintoEbookBundleQuery` and pass it any options that fit your needs.
 * When your component renders, `usePintoEbookBundleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePintoEbookBundleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePintoEbookBundleQuery(baseOptions: Apollo.QueryHookOptions<Types.PintoEbookBundleQuery, Types.PintoEbookBundleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.PintoEbookBundleQuery, Types.PintoEbookBundleQueryVariables>(PintoEbookBundleDocument, options);
}
export function usePintoEbookBundleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PintoEbookBundleQuery, Types.PintoEbookBundleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.PintoEbookBundleQuery, Types.PintoEbookBundleQueryVariables>(PintoEbookBundleDocument, options);
}
export type PintoEbookBundleQueryHookResult = ReturnType<typeof usePintoEbookBundleQuery>;
export type PintoEbookBundleLazyQueryHookResult = ReturnType<typeof usePintoEbookBundleLazyQuery>;
export type PintoEbookBundleQueryResult = Apollo.QueryResult<Types.PintoEbookBundleQuery, Types.PintoEbookBundleQueryVariables>;
export const PintoEbookBundleSubmissionsDocument = gql`
  query PintoEbookBundleSubmissions {
    ebookBundleSubmissions {
      _id
      fileName
      createdAt
      adminNote
      ebookBundleCount
    }
  }
`;

/**
 * __usePintoEbookBundleSubmissionsQuery__
 *
 * To run a query within a React component, call `usePintoEbookBundleSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePintoEbookBundleSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePintoEbookBundleSubmissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePintoEbookBundleSubmissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.PintoEbookBundleSubmissionsQuery, Types.PintoEbookBundleSubmissionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.PintoEbookBundleSubmissionsQuery, Types.PintoEbookBundleSubmissionsQueryVariables>(PintoEbookBundleSubmissionsDocument, options);
}
export function usePintoEbookBundleSubmissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.PintoEbookBundleSubmissionsQuery, Types.PintoEbookBundleSubmissionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.PintoEbookBundleSubmissionsQuery, Types.PintoEbookBundleSubmissionsQueryVariables>(PintoEbookBundleSubmissionsDocument, options);
}
export type PintoEbookBundleSubmissionsQueryHookResult = ReturnType<typeof usePintoEbookBundleSubmissionsQuery>;
export type PintoEbookBundleSubmissionsLazyQueryHookResult = ReturnType<typeof usePintoEbookBundleSubmissionsLazyQuery>;
export type PintoEbookBundleSubmissionsQueryResult = Apollo.QueryResult<Types.PintoEbookBundleSubmissionsQuery, Types.PintoEbookBundleSubmissionsQueryVariables>;
export const PintoEbookDiscountCampaignDocument = gql`
  query PintoEbookDiscountCampaign($ebookDiscountCampaignId: ID!) {
    ebookDiscountCampaign(ebookDiscountCampaignId: $ebookDiscountCampaignId) {
      ...EbookDiscountCampaign
    }
  }
  ${EbookDiscountCampaignFragmentDoc}
`;

/**
 * __usePintoEbookDiscountCampaignQuery__
 *
 * To run a query within a React component, call `usePintoEbookDiscountCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `usePintoEbookDiscountCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePintoEbookDiscountCampaignQuery({
 *   variables: {
 *      ebookDiscountCampaignId: // value for 'ebookDiscountCampaignId'
 *   },
 * });
 */
export function usePintoEbookDiscountCampaignQuery(baseOptions: Apollo.QueryHookOptions<Types.PintoEbookDiscountCampaignQuery, Types.PintoEbookDiscountCampaignQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.PintoEbookDiscountCampaignQuery, Types.PintoEbookDiscountCampaignQueryVariables>(PintoEbookDiscountCampaignDocument, options);
}
export function usePintoEbookDiscountCampaignLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.PintoEbookDiscountCampaignQuery, Types.PintoEbookDiscountCampaignQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.PintoEbookDiscountCampaignQuery, Types.PintoEbookDiscountCampaignQueryVariables>(PintoEbookDiscountCampaignDocument, options);
}
export type PintoEbookDiscountCampaignQueryHookResult = ReturnType<typeof usePintoEbookDiscountCampaignQuery>;
export type PintoEbookDiscountCampaignLazyQueryHookResult = ReturnType<typeof usePintoEbookDiscountCampaignLazyQuery>;
export type PintoEbookDiscountCampaignQueryResult = Apollo.QueryResult<Types.PintoEbookDiscountCampaignQuery, Types.PintoEbookDiscountCampaignQueryVariables>;
export const PintoEbookDiscountCampaignsDocument = gql`
  query PintoEbookDiscountCampaigns($filter: EbookDiscountCampaignsFilter) {
    ebookDiscountCampaigns(filter: $filter) {
      totalCount
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          ...EbookDiscountCampaign
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${EbookDiscountCampaignFragmentDoc}
`;

/**
 * __usePintoEbookDiscountCampaignsQuery__
 *
 * To run a query within a React component, call `usePintoEbookDiscountCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePintoEbookDiscountCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePintoEbookDiscountCampaignsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePintoEbookDiscountCampaignsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.PintoEbookDiscountCampaignsQuery, Types.PintoEbookDiscountCampaignsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.PintoEbookDiscountCampaignsQuery, Types.PintoEbookDiscountCampaignsQueryVariables>(PintoEbookDiscountCampaignsDocument, options);
}
export function usePintoEbookDiscountCampaignsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.PintoEbookDiscountCampaignsQuery, Types.PintoEbookDiscountCampaignsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.PintoEbookDiscountCampaignsQuery, Types.PintoEbookDiscountCampaignsQueryVariables>(PintoEbookDiscountCampaignsDocument, options);
}
export type PintoEbookDiscountCampaignsQueryHookResult = ReturnType<typeof usePintoEbookDiscountCampaignsQuery>;
export type PintoEbookDiscountCampaignsLazyQueryHookResult = ReturnType<typeof usePintoEbookDiscountCampaignsLazyQuery>;
export type PintoEbookDiscountCampaignsQueryResult = Apollo.QueryResult<Types.PintoEbookDiscountCampaignsQuery, Types.PintoEbookDiscountCampaignsQueryVariables>;
export const PintoEbooksDocument = gql`
  query PintoEbooks($filter: EbooksFilter) {
    ebooks(filter: $filter) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          _id
          title
          coverImageUrl
          status
          correctionEbookStatus
          updatedAt
          originalLanguage {
            name
            code
          }
          language {
            name
            code
          }
          category {
            _id
            name
          }
          correction {
            categoryId
            category {
              _id
              contentType
              canSetPrice
              name
              parentId
              parent {
                _id
                contentType
                canSetPrice
                name
                parentId
              }
            }
          }
          user {
            _id
            displayName
            username
          }
        }
      }
    }
  }
`;

/**
 * __usePintoEbooksQuery__
 *
 * To run a query within a React component, call `usePintoEbooksQuery` and pass it any options that fit your needs.
 * When your component renders, `usePintoEbooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePintoEbooksQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePintoEbooksQuery(baseOptions?: Apollo.QueryHookOptions<Types.PintoEbooksQuery, Types.PintoEbooksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.PintoEbooksQuery, Types.PintoEbooksQueryVariables>(PintoEbooksDocument, options);
}
export function usePintoEbooksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PintoEbooksQuery, Types.PintoEbooksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.PintoEbooksQuery, Types.PintoEbooksQueryVariables>(PintoEbooksDocument, options);
}
export type PintoEbooksQueryHookResult = ReturnType<typeof usePintoEbooksQuery>;
export type PintoEbooksLazyQueryHookResult = ReturnType<typeof usePintoEbooksLazyQuery>;
export type PintoEbooksQueryResult = Apollo.QueryResult<Types.PintoEbooksQuery, Types.PintoEbooksQueryVariables>;
export const PintoFeedDocument = gql`
  query PintoFeed($slug: String!, $pref: Preference!) {
    pintoFeed(slug: $slug, pref: $pref) {
      ...Feed
    }
  }
  ${FeedFragmentDoc}
`;

/**
 * __usePintoFeedQuery__
 *
 * To run a query within a React component, call `usePintoFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `usePintoFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePintoFeedQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      pref: // value for 'pref'
 *   },
 * });
 */
export function usePintoFeedQuery(baseOptions: Apollo.QueryHookOptions<Types.PintoFeedQuery, Types.PintoFeedQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.PintoFeedQuery, Types.PintoFeedQueryVariables>(PintoFeedDocument, options);
}
export function usePintoFeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PintoFeedQuery, Types.PintoFeedQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.PintoFeedQuery, Types.PintoFeedQueryVariables>(PintoFeedDocument, options);
}
export type PintoFeedQueryHookResult = ReturnType<typeof usePintoFeedQuery>;
export type PintoFeedLazyQueryHookResult = ReturnType<typeof usePintoFeedLazyQuery>;
export type PintoFeedQueryResult = Apollo.QueryResult<Types.PintoFeedQuery, Types.PintoFeedQueryVariables>;
export const PintoFeedsDocument = gql`
  query PintoFeeds($filter: FeedsFilterV2) {
    pintoFeeds(filter: $filter) {
      totalCount
      pageInfo {
        ...PageInfo
      }
      edges {
        cursor
        node {
          ...Feed
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${FeedFragmentDoc}
`;

/**
 * __usePintoFeedsQuery__
 *
 * To run a query within a React component, call `usePintoFeedsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePintoFeedsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePintoFeedsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePintoFeedsQuery(baseOptions?: Apollo.QueryHookOptions<Types.PintoFeedsQuery, Types.PintoFeedsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.PintoFeedsQuery, Types.PintoFeedsQueryVariables>(PintoFeedsDocument, options);
}
export function usePintoFeedsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PintoFeedsQuery, Types.PintoFeedsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.PintoFeedsQuery, Types.PintoFeedsQueryVariables>(PintoFeedsDocument, options);
}
export type PintoFeedsQueryHookResult = ReturnType<typeof usePintoFeedsQuery>;
export type PintoFeedsLazyQueryHookResult = ReturnType<typeof usePintoFeedsLazyQuery>;
export type PintoFeedsQueryResult = Apollo.QueryResult<Types.PintoFeedsQuery, Types.PintoFeedsQueryVariables>;
export const PintoProductSetDocument = gql`
  query PintoProductSet($productSetId: ID!) {
    pintoProductSet(productSetId: $productSetId) {
      ...PintoProductSet
    }
  }
  ${PintoProductSetFragmentDoc}
`;

/**
 * __usePintoProductSetQuery__
 *
 * To run a query within a React component, call `usePintoProductSetQuery` and pass it any options that fit your needs.
 * When your component renders, `usePintoProductSetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePintoProductSetQuery({
 *   variables: {
 *      productSetId: // value for 'productSetId'
 *   },
 * });
 */
export function usePintoProductSetQuery(baseOptions: Apollo.QueryHookOptions<Types.PintoProductSetQuery, Types.PintoProductSetQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.PintoProductSetQuery, Types.PintoProductSetQueryVariables>(PintoProductSetDocument, options);
}
export function usePintoProductSetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PintoProductSetQuery, Types.PintoProductSetQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.PintoProductSetQuery, Types.PintoProductSetQueryVariables>(PintoProductSetDocument, options);
}
export type PintoProductSetQueryHookResult = ReturnType<typeof usePintoProductSetQuery>;
export type PintoProductSetLazyQueryHookResult = ReturnType<typeof usePintoProductSetLazyQuery>;
export type PintoProductSetQueryResult = Apollo.QueryResult<Types.PintoProductSetQuery, Types.PintoProductSetQueryVariables>;
export const PintoProductSetsDocument = gql`
  query PintoProductSets($filter: PintoProductSetsFilter) {
    pintoProductSets(filter: $filter) {
      totalCount
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          ...PintoProductSet
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${PintoProductSetFragmentDoc}
`;

/**
 * __usePintoProductSetsQuery__
 *
 * To run a query within a React component, call `usePintoProductSetsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePintoProductSetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePintoProductSetsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePintoProductSetsQuery(baseOptions?: Apollo.QueryHookOptions<Types.PintoProductSetsQuery, Types.PintoProductSetsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.PintoProductSetsQuery, Types.PintoProductSetsQueryVariables>(PintoProductSetsDocument, options);
}
export function usePintoProductSetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PintoProductSetsQuery, Types.PintoProductSetsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.PintoProductSetsQuery, Types.PintoProductSetsQueryVariables>(PintoProductSetsDocument, options);
}
export type PintoProductSetsQueryHookResult = ReturnType<typeof usePintoProductSetsQuery>;
export type PintoProductSetsLazyQueryHookResult = ReturnType<typeof usePintoProductSetsLazyQuery>;
export type PintoProductSetsQueryResult = Apollo.QueryResult<Types.PintoProductSetsQuery, Types.PintoProductSetsQueryVariables>;
export const PintoSectionDocument = gql`
  query PintoSection($id: ID!) {
    pintoSection(id: $id) {
      _id
      pref
      productType
      contentType
      camelCaseLayoutId
      isNCContent
      isActivated
      title
      configItems
      items
      note
      createdAt
      updatedAt
      target {
        __typename
        ... on Feed {
          _id
          slug
        }
        ... on Link {
          url
        }
        ... on User {
          _id
        }
        ... on SearchLink {
          searchType
          searchText
          searchField
          categoryId
          sortBy
          sortType
          gteRating
          anyTypes
          anyPriceTypes
          anyWritingTypes
        }
        ... on Explore {
          exploreType
          sortBy
          sortType
          categoryId
          gteRating
          anyPriceTypes
          anyTypes
          anyFileTypes
          anyWritingTypes
        }
        ... on ExploreProductSet {
          sortBy
          sortType
          categoryId
        }
      }
    }
  }
`;

/**
 * __usePintoSectionQuery__
 *
 * To run a query within a React component, call `usePintoSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePintoSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePintoSectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePintoSectionQuery(baseOptions: Apollo.QueryHookOptions<Types.PintoSectionQuery, Types.PintoSectionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.PintoSectionQuery, Types.PintoSectionQueryVariables>(PintoSectionDocument, options);
}
export function usePintoSectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PintoSectionQuery, Types.PintoSectionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.PintoSectionQuery, Types.PintoSectionQueryVariables>(PintoSectionDocument, options);
}
export type PintoSectionQueryHookResult = ReturnType<typeof usePintoSectionQuery>;
export type PintoSectionLazyQueryHookResult = ReturnType<typeof usePintoSectionLazyQuery>;
export type PintoSectionQueryResult = Apollo.QueryResult<Types.PintoSectionQuery, Types.PintoSectionQueryVariables>;
export const PintoSectionsDocument = gql`
  query PintoSections($filter: SectionsFilterV2) {
    pintoSections(filter: $filter) {
      totalCount
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          _id
          note
          isNCContent
          updatedAt
          camelCaseLayoutId
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __usePintoSectionsQuery__
 *
 * To run a query within a React component, call `usePintoSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePintoSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePintoSectionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePintoSectionsQuery(baseOptions?: Apollo.QueryHookOptions<Types.PintoSectionsQuery, Types.PintoSectionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.PintoSectionsQuery, Types.PintoSectionsQueryVariables>(PintoSectionsDocument, options);
}
export function usePintoSectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PintoSectionsQuery, Types.PintoSectionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.PintoSectionsQuery, Types.PintoSectionsQueryVariables>(PintoSectionsDocument, options);
}
export type PintoSectionsQueryHookResult = ReturnType<typeof usePintoSectionsQuery>;
export type PintoSectionsLazyQueryHookResult = ReturnType<typeof usePintoSectionsLazyQuery>;
export type PintoSectionsQueryResult = Apollo.QueryResult<Types.PintoSectionsQuery, Types.PintoSectionsQueryVariables>;
export const PintoSubscriptionEbooksDocument = gql`
  query PintoSubscriptionEbooks($filter: EbooksFilter) {
    ebooks(filter: $filter) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          _id
          title
          status
          createdAt
          updatedAt
        }
      }
    }
  }
`;

/**
 * __usePintoSubscriptionEbooksQuery__
 *
 * To run a query within a React component, call `usePintoSubscriptionEbooksQuery` and pass it any options that fit your needs.
 * When your component renders, `usePintoSubscriptionEbooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePintoSubscriptionEbooksQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePintoSubscriptionEbooksQuery(baseOptions?: Apollo.QueryHookOptions<Types.PintoSubscriptionEbooksQuery, Types.PintoSubscriptionEbooksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.PintoSubscriptionEbooksQuery, Types.PintoSubscriptionEbooksQueryVariables>(PintoSubscriptionEbooksDocument, options);
}
export function usePintoSubscriptionEbooksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PintoSubscriptionEbooksQuery, Types.PintoSubscriptionEbooksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.PintoSubscriptionEbooksQuery, Types.PintoSubscriptionEbooksQueryVariables>(PintoSubscriptionEbooksDocument, options);
}
export type PintoSubscriptionEbooksQueryHookResult = ReturnType<typeof usePintoSubscriptionEbooksQuery>;
export type PintoSubscriptionEbooksLazyQueryHookResult = ReturnType<typeof usePintoSubscriptionEbooksLazyQuery>;
export type PintoSubscriptionEbooksQueryResult = Apollo.QueryResult<Types.PintoSubscriptionEbooksQuery, Types.PintoSubscriptionEbooksQueryVariables>;
export const PintoSubscriptionTitleDocument = gql`
  query PintoSubscriptionTitle($subscriptionTitleId: ID!) {
    subscriptionTitle(subscriptionTitleId: $subscriptionTitleId) {
      ...SubscriptionTitleDetail
    }
  }
  ${SubscriptionTitleDetailFragmentDoc}
`;

/**
 * __usePintoSubscriptionTitleQuery__
 *
 * To run a query within a React component, call `usePintoSubscriptionTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `usePintoSubscriptionTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePintoSubscriptionTitleQuery({
 *   variables: {
 *      subscriptionTitleId: // value for 'subscriptionTitleId'
 *   },
 * });
 */
export function usePintoSubscriptionTitleQuery(baseOptions: Apollo.QueryHookOptions<Types.PintoSubscriptionTitleQuery, Types.PintoSubscriptionTitleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.PintoSubscriptionTitleQuery, Types.PintoSubscriptionTitleQueryVariables>(PintoSubscriptionTitleDocument, options);
}
export function usePintoSubscriptionTitleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PintoSubscriptionTitleQuery, Types.PintoSubscriptionTitleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.PintoSubscriptionTitleQuery, Types.PintoSubscriptionTitleQueryVariables>(PintoSubscriptionTitleDocument, options);
}
export type PintoSubscriptionTitleQueryHookResult = ReturnType<typeof usePintoSubscriptionTitleQuery>;
export type PintoSubscriptionTitleLazyQueryHookResult = ReturnType<typeof usePintoSubscriptionTitleLazyQuery>;
export type PintoSubscriptionTitleQueryResult = Apollo.QueryResult<Types.PintoSubscriptionTitleQuery, Types.PintoSubscriptionTitleQueryVariables>;
export const PintoVoucherDocument = gql`
  query PintoVoucher($code: ID!) {
    pintoVoucher(code: $code) {
      ...PintoVoucher
    }
  }
  ${PintoVoucherFragmentDoc}
`;

/**
 * __usePintoVoucherQuery__
 *
 * To run a query within a React component, call `usePintoVoucherQuery` and pass it any options that fit your needs.
 * When your component renders, `usePintoVoucherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePintoVoucherQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function usePintoVoucherQuery(baseOptions: Apollo.QueryHookOptions<Types.PintoVoucherQuery, Types.PintoVoucherQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.PintoVoucherQuery, Types.PintoVoucherQueryVariables>(PintoVoucherDocument, options);
}
export function usePintoVoucherLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PintoVoucherQuery, Types.PintoVoucherQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.PintoVoucherQuery, Types.PintoVoucherQueryVariables>(PintoVoucherDocument, options);
}
export type PintoVoucherQueryHookResult = ReturnType<typeof usePintoVoucherQuery>;
export type PintoVoucherLazyQueryHookResult = ReturnType<typeof usePintoVoucherLazyQuery>;
export type PintoVoucherQueryResult = Apollo.QueryResult<Types.PintoVoucherQuery, Types.PintoVoucherQueryVariables>;
export const PintoVouchersDocument = gql`
  query PintoVouchers($filter: PintoVouchersFilter) {
    pintoVouchers(filter: $filter) {
      totalCount
      pageInfo {
        ...PageInfo
      }
      edges {
        cursor
        node {
          ...PintoVoucher
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${PintoVoucherFragmentDoc}
`;

/**
 * __usePintoVouchersQuery__
 *
 * To run a query within a React component, call `usePintoVouchersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePintoVouchersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePintoVouchersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePintoVouchersQuery(baseOptions?: Apollo.QueryHookOptions<Types.PintoVouchersQuery, Types.PintoVouchersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.PintoVouchersQuery, Types.PintoVouchersQueryVariables>(PintoVouchersDocument, options);
}
export function usePintoVouchersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PintoVouchersQuery, Types.PintoVouchersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.PintoVouchersQuery, Types.PintoVouchersQueryVariables>(PintoVouchersDocument, options);
}
export type PintoVouchersQueryHookResult = ReturnType<typeof usePintoVouchersQuery>;
export type PintoVouchersLazyQueryHookResult = ReturnType<typeof usePintoVouchersLazyQuery>;
export type PintoVouchersQueryResult = Apollo.QueryResult<Types.PintoVouchersQuery, Types.PintoVouchersQueryVariables>;
export const PopupDocument = gql`
  query Popup($id: ID!) {
    popup(id: $id) {
      ...PopupNode
    }
  }
  ${PopupNodeFragmentDoc}
`;

/**
 * __usePopupQuery__
 *
 * To run a query within a React component, call `usePopupQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePopupQuery(baseOptions: Apollo.QueryHookOptions<Types.PopupQuery, Types.PopupQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.PopupQuery, Types.PopupQueryVariables>(PopupDocument, options);
}
export function usePopupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PopupQuery, Types.PopupQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.PopupQuery, Types.PopupQueryVariables>(PopupDocument, options);
}
export type PopupQueryHookResult = ReturnType<typeof usePopupQuery>;
export type PopupLazyQueryHookResult = ReturnType<typeof usePopupLazyQuery>;
export type PopupQueryResult = Apollo.QueryResult<Types.PopupQuery, Types.PopupQueryVariables>;
export const PopupsDocument = gql`
  query Popups($filter: PopupsFilter) {
    popups(filter: $filter) {
      edges {
        node {
          ...PopupNodeWithoutItems
        }
      }
    }
  }
  ${PopupNodeWithoutItemsFragmentDoc}
`;

/**
 * __usePopupsQuery__
 *
 * To run a query within a React component, call `usePopupsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopupsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePopupsQuery(baseOptions?: Apollo.QueryHookOptions<Types.PopupsQuery, Types.PopupsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.PopupsQuery, Types.PopupsQueryVariables>(PopupsDocument, options);
}
export function usePopupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PopupsQuery, Types.PopupsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.PopupsQuery, Types.PopupsQueryVariables>(PopupsDocument, options);
}
export type PopupsQueryHookResult = ReturnType<typeof usePopupsQuery>;
export type PopupsLazyQueryHookResult = ReturnType<typeof usePopupsLazyQuery>;
export type PopupsQueryResult = Apollo.QueryResult<Types.PopupsQuery, Types.PopupsQueryVariables>;
export const PreviewSectionDocument = gql`
  query PreviewSection($id: ID!) {
    previewSection(id: $id) {
      ...SectionV3
    }
  }
  ${SectionV3FragmentDoc}
`;

/**
 * __usePreviewSectionQuery__
 *
 * To run a query within a React component, call `usePreviewSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewSectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePreviewSectionQuery(baseOptions: Apollo.QueryHookOptions<Types.PreviewSectionQuery, Types.PreviewSectionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.PreviewSectionQuery, Types.PreviewSectionQueryVariables>(PreviewSectionDocument, options);
}
export function usePreviewSectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PreviewSectionQuery, Types.PreviewSectionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.PreviewSectionQuery, Types.PreviewSectionQueryVariables>(PreviewSectionDocument, options);
}
export type PreviewSectionQueryHookResult = ReturnType<typeof usePreviewSectionQuery>;
export type PreviewSectionLazyQueryHookResult = ReturnType<typeof usePreviewSectionLazyQuery>;
export type PreviewSectionQueryResult = Apollo.QueryResult<Types.PreviewSectionQuery, Types.PreviewSectionQueryVariables>;
export const RequestedEbookFilesDocument = gql`
  query RequestedEbookFiles($filter: EditEbookFilter) {
    requestedEbookFiles(filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          _id
          ...EditEbook
        }
      }
    }
  }
  ${EditEbookFragmentDoc}
`;

/**
 * __useRequestedEbookFilesQuery__
 *
 * To run a query within a React component, call `useRequestedEbookFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestedEbookFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestedEbookFilesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRequestedEbookFilesQuery(baseOptions?: Apollo.QueryHookOptions<Types.RequestedEbookFilesQuery, Types.RequestedEbookFilesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.RequestedEbookFilesQuery, Types.RequestedEbookFilesQueryVariables>(RequestedEbookFilesDocument, options);
}
export function useRequestedEbookFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RequestedEbookFilesQuery, Types.RequestedEbookFilesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.RequestedEbookFilesQuery, Types.RequestedEbookFilesQueryVariables>(RequestedEbookFilesDocument, options);
}
export type RequestedEbookFilesQueryHookResult = ReturnType<typeof useRequestedEbookFilesQuery>;
export type RequestedEbookFilesLazyQueryHookResult = ReturnType<typeof useRequestedEbookFilesLazyQuery>;
export type RequestedEbookFilesQueryResult = Apollo.QueryResult<Types.RequestedEbookFilesQuery, Types.RequestedEbookFilesQueryVariables>;
export const SearchTagsDocument = gql`
  query SearchTags($filter: SearchTagsFilter, $q: String) {
    searchTags(filter: $filter, q: $q) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...Tag
        }
      }
    }
  }
  ${TagFragmentDoc}
`;

/**
 * __useSearchTagsQuery__
 *
 * To run a query within a React component, call `useSearchTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTagsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      q: // value for 'q'
 *   },
 * });
 */
export function useSearchTagsQuery(baseOptions?: Apollo.QueryHookOptions<Types.SearchTagsQuery, Types.SearchTagsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.SearchTagsQuery, Types.SearchTagsQueryVariables>(SearchTagsDocument, options);
}
export function useSearchTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchTagsQuery, Types.SearchTagsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.SearchTagsQuery, Types.SearchTagsQueryVariables>(SearchTagsDocument, options);
}
export type SearchTagsQueryHookResult = ReturnType<typeof useSearchTagsQuery>;
export type SearchTagsLazyQueryHookResult = ReturnType<typeof useSearchTagsLazyQuery>;
export type SearchTagsQueryResult = Apollo.QueryResult<Types.SearchTagsQuery, Types.SearchTagsQueryVariables>;
export const SearchUsersDocument = gql`
  query SearchUsers($q: String, $filter: SearchUsersFilter) {
    searchUsers(q: $q, filter: $filter) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          _id
          username
          email
          displayName
          profileImage
          type
          isPremium
        }
      }
    }
  }
`;

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      q: // value for 'q'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSearchUsersQuery(baseOptions?: Apollo.QueryHookOptions<Types.SearchUsersQuery, Types.SearchUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.SearchUsersQuery, Types.SearchUsersQueryVariables>(SearchUsersDocument, options);
}
export function useSearchUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchUsersQuery, Types.SearchUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.SearchUsersQuery, Types.SearchUsersQueryVariables>(SearchUsersDocument, options);
}
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<typeof useSearchUsersLazyQuery>;
export type SearchUsersQueryResult = Apollo.QueryResult<Types.SearchUsersQuery, Types.SearchUsersQueryVariables>;
export const SectionPaginationDocument = gql`
  query SectionPagination($filter: SectionsFilterV2) {
    sectionPagination(filter: $filter) {
      pageInfo {
        ...PageInfo
      }
      edges {
        cursor
        node {
          ...SectionV3WithoutItems
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${SectionV3WithoutItemsFragmentDoc}
`;

/**
 * __useSectionPaginationQuery__
 *
 * To run a query within a React component, call `useSectionPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectionPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSectionPaginationQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSectionPaginationQuery(baseOptions?: Apollo.QueryHookOptions<Types.SectionPaginationQuery, Types.SectionPaginationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.SectionPaginationQuery, Types.SectionPaginationQueryVariables>(SectionPaginationDocument, options);
}
export function useSectionPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SectionPaginationQuery, Types.SectionPaginationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.SectionPaginationQuery, Types.SectionPaginationQueryVariables>(SectionPaginationDocument, options);
}
export type SectionPaginationQueryHookResult = ReturnType<typeof useSectionPaginationQuery>;
export type SectionPaginationLazyQueryHookResult = ReturnType<typeof useSectionPaginationLazyQuery>;
export type SectionPaginationQueryResult = Apollo.QueryResult<Types.SectionPaginationQuery, Types.SectionPaginationQueryVariables>;
export const TemplateByQueryDocument = gql`
  query TemplateByQuery($filter: TemplateFilter) {
    templateByQuery(filter: $filter) {
      ...Template
    }
  }
  ${TemplateFragmentDoc}
`;

/**
 * __useTemplateByQueryQuery__
 *
 * To run a query within a React component, call `useTemplateByQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateByQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateByQueryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTemplateByQueryQuery(baseOptions?: Apollo.QueryHookOptions<Types.TemplateByQueryQuery, Types.TemplateByQueryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.TemplateByQueryQuery, Types.TemplateByQueryQueryVariables>(TemplateByQueryDocument, options);
}
export function useTemplateByQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TemplateByQueryQuery, Types.TemplateByQueryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.TemplateByQueryQuery, Types.TemplateByQueryQueryVariables>(TemplateByQueryDocument, options);
}
export type TemplateByQueryQueryHookResult = ReturnType<typeof useTemplateByQueryQuery>;
export type TemplateByQueryLazyQueryHookResult = ReturnType<typeof useTemplateByQueryLazyQuery>;
export type TemplateByQueryQueryResult = Apollo.QueryResult<Types.TemplateByQueryQuery, Types.TemplateByQueryQueryVariables>;
export const TemplatesDocument = gql`
  query Templates($filter: TemplatesFilter) {
    templates(filter: $filter) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          ...TemplateNode
        }
      }
    }
  }
  ${TemplateNodeFragmentDoc}
`;

/**
 * __useTemplatesQuery__
 *
 * To run a query within a React component, call `useTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplatesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<Types.TemplatesQuery, Types.TemplatesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.TemplatesQuery, Types.TemplatesQueryVariables>(TemplatesDocument, options);
}
export function useTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TemplatesQuery, Types.TemplatesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.TemplatesQuery, Types.TemplatesQueryVariables>(TemplatesDocument, options);
}
export type TemplatesQueryHookResult = ReturnType<typeof useTemplatesQuery>;
export type TemplatesLazyQueryHookResult = ReturnType<typeof useTemplatesLazyQuery>;
export type TemplatesQueryResult = Apollo.QueryResult<Types.TemplatesQuery, Types.TemplatesQueryVariables>;
