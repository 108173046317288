export type PossibleTypesResultData = {
  possibleTypes: {
    IPopupItemConfig: [
      'PopupItemConfigBook',
      'PopupItemConfigEbook',
      'PopupItemConfigExploreBook',
      'PopupItemConfigExploreEbook',
      'PopupItemConfigFeed',
      'PopupItemConfigLink',
      'PopupItemConfigProductSet',
      'PopupItemConfigSearch',
      'PopupItemConfigSection',
      'PopupItemConfigStatic',
    ];
    ItemResult: ['Book', 'Ebook'];
    PintoVoucherParticipatingItemData: ['PintoCategory', 'User'];
    Product: ['Book', 'Ebook'];
    PurchaseTransactionBillItem: ['Book', 'Ebook'];
    SectionTarget: ['Explore', 'ExploreProductSet', 'Feed', 'Link', 'SearchLink', 'SectionTargetProductSet', 'SectionTargetSection', 'User'];
    UnionSectionItem: ['Book', 'Ebook', 'EbookBundle', 'Explore', 'Feed', 'Link', 'ProductSet', 'SearchLink', 'Section', 'Static'];
  };
};
const result: PossibleTypesResultData = {
  possibleTypes: {
    IPopupItemConfig: [
      'PopupItemConfigBook',
      'PopupItemConfigEbook',
      'PopupItemConfigExploreBook',
      'PopupItemConfigExploreEbook',
      'PopupItemConfigFeed',
      'PopupItemConfigLink',
      'PopupItemConfigProductSet',
      'PopupItemConfigSearch',
      'PopupItemConfigSection',
      'PopupItemConfigStatic',
    ],
    ItemResult: ['Book', 'Ebook'],
    PintoVoucherParticipatingItemData: ['PintoCategory', 'User'],
    Product: ['Book', 'Ebook'],
    PurchaseTransactionBillItem: ['Book', 'Ebook'],
    SectionTarget: ['Explore', 'ExploreProductSet', 'Feed', 'Link', 'SearchLink', 'SectionTargetProductSet', 'SectionTargetSection', 'User'],
    UnionSectionItem: ['Book', 'Ebook', 'EbookBundle', 'Explore', 'Feed', 'Link', 'ProductSet', 'SearchLink', 'Section', 'Static'],
  },
};
export default result;
