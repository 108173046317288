import { useCallback } from 'react';

import { usePintoVouchersDeleteMutation } from '../core/graphql/operation-hooks';

const useMutationPintoVouchersDelete = () => {
  const [mutatePintoVouchersDelete] = usePintoVouchersDeleteMutation();

  const deleteVouchers = useCallback(
    async (codes: string) => {
      const resp = await mutatePintoVouchersDelete({
        variables: { codes: [codes] },
        refetchQueries: ['PintoVouchers'],
      });

      return resp.data?.deletePintoVouchers.success;
    },
    [mutatePintoVouchersDelete],
  );

  return {
    deleteVouchers,
  };
};

export default useMutationPintoVouchersDelete;
