import classNames from 'classnames';
import { useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { Link } from 'react-router-dom';

import Button from '../../../components/Button';
import { PintoVoucherStatus } from '../../../core/graphql/types';
import { numberWithCommasWithDecimalPlace } from '../../../utils/convertNumber';
import { getDayTime } from '../../../utils/dayjs';

export interface IPropsCouponListItem {
  _id: string;
  isMembershipOnly?: boolean;
  membershipStartedAt?: string;
  redeemedCounts: number;
  maximumRedeemedCounts: number;
  startedAt?: string;
  expiredAt?: string;
  title: string;
  description: string;
  conditions: string[];
  minimumSpendThb: number;
  maximumDiscountAmount?: number;
  discount: string;
  status: PintoVoucherStatus;
  isEnable: boolean;
  onDelete?: () => void;
  onToggleEnable?: () => void;
}

export const COUPON_STATUS = {
  [PintoVoucherStatus.Scheduled]: 'Scheduled',
  [PintoVoucherStatus.Active]: 'Active',
  [PintoVoucherStatus.Expired]: 'Expired',
};

export const COUPON_STATUS_CLASS_NAME = {
  [PintoVoucherStatus.Scheduled]: 'badge',
  [PintoVoucherStatus.Active]: 'badge badge-warning',
  [PintoVoucherStatus.Expired]: 'badge badge-error',
};
const DATE_FORMAT = 'DD-MM-YYYY hh:mm A';

const CouponListItem = ({
  _id,
  membershipStartedAt,
  isMembershipOnly,
  startedAt,
  expiredAt,
  minimumSpendThb,
  maximumDiscountAmount,
  discount,
  title,
  status,
  isEnable,
  onDelete,
  onToggleEnable,
}: IPropsCouponListItem) => {
  const [showConfirmHideCouponModal, setShowConfirmHideCouponModal] = useState<boolean>(false);

  return (
    <tr className="hover font-tt">
      <th>
        <Link to={`/coupons/detail?code=${_id}`} className="hover:underline">
          {title}
        </Link>
      </th>
      <td className="text-colorsBrandWarmBlack02LabelSecondary">{numberWithCommasWithDecimalPlace(minimumSpendThb)}</td>
      <td className="text-colorsBrandWarmBlack02LabelSecondary">{discount}</td>
      <td className="text-colorsBrandWarmBlack02LabelSecondary">{maximumDiscountAmount ? numberWithCommasWithDecimalPlace(maximumDiscountAmount) : '-'}</td>
      <td>
        <div className="flex gap-2">
          <div className="w-[50px]">VIP:</div>
          <span>{membershipStartedAt || isMembershipOnly ? getDayTime(membershipStartedAt || startedAt || '', DATE_FORMAT) : '-'}</span>
        </div>

        <div className="flex gap-2 text-colorsBrandWarmBlack02LabelSecondary">
          <div className="w-[50px]">Start:</div>
          <span>{isMembershipOnly ? '-' : getDayTime(startedAt || '', DATE_FORMAT)}</span>
        </div>
        <div className="flex gap-2 text-colorsBrandWarmBlack02LabelSecondary">
          <div className="w-[50px]">End:</div>
          <span>{expiredAt ? getDayTime(expiredAt || '', DATE_FORMAT) : 'ไม่มีวันหมดอายุ'}</span>
        </div>
      </td>
      <td className="text-colorsBrandWarmBlack02LabelSecondary text-center">{isMembershipOnly ? '/' : 'x'}</td>
      <td>
        <div className={classNames('gap-2', COUPON_STATUS_CLASS_NAME[status])}>
          <span>{COUPON_STATUS[status]}</span>
        </div>
      </td>
      <th>
        {[PintoVoucherStatus.Scheduled].includes(status) && (
          <Button type="ghost" color="red" className="space-x-4 text-colorsBrandRed01Primary" onClick={onDelete}>
            <IoMdClose />
            <span>delete</span>
          </Button>
        )}
        {[PintoVoucherStatus.Active].includes(status) && (
          <div className="dropdown dropdown-bottom dropdown-end">
            <label tabIndex={0} className="m-1">
              <Button type="ghost" color="black" className="space-x-4 !px-0" onClick={() => setShowConfirmHideCouponModal(true)}>
                <input type="checkbox" className="toggle toggle-accent" checked={isEnable} />
                <div
                  className={classNames({
                    'text-colorsBrandWarmBlack02LabelSecondary': !isEnable,
                    'text-colorsPintoBlueprint01Primary': isEnable,
                  })}
                >
                  {!isEnable ? 'Unpublish' : 'Publish'}
                </div>
              </Button>
            </label>
            {showConfirmHideCouponModal && (
              <div tabIndex={0} className="dropdown-content card card-compact p-2 w-[350px] shadow bg-base-100">
                <div className="card-body space-y-12 !p-24">
                  <h3 className="card-title font-dbh !mb-0 flex items-center">
                    <span className="mr-4">{isEnable ? <MdVisibilityOff size={24} /> : <MdVisibility size={24} />}</span>
                    {`${isEnable ? 'Unpublish' : 'Publish'} Confirmation`}
                  </h3>
                  <div>
                    <p className="font-tt">{`Are you confirm to ${isEnable ? 'unpublish' : 'publish'}: `}</p>
                    <p className="font-tt">{_id}?</p>
                  </div>
                  <div className="flex justify-center w-full space-x-6">
                    <button className="btn btn-outline" onClick={() => setShowConfirmHideCouponModal(false)}>
                      Cancel
                    </button>
                    <button
                      className="btn btn-error"
                      onClick={() => {
                        onToggleEnable?.();
                        setShowConfirmHideCouponModal(false);
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </th>
    </tr>
  );
};

export default CouponListItem;
