import classNames from 'classnames';

import Card from '../../../components/Card';
import { PintoVoucherParticipatingItem, PintoVoucherParticipatingItemType, PintoVoucherStatus } from '../../../core/graphql/types';
import { numberWithCommas, numberWithCommasWithDecimalPlace } from '../../../utils/convertNumber';
import { getDayTime } from '../../../utils/dayjs';
import { COUPON_STATUS_CLASS_NAME, COUPON_STATUS } from '../components/CouponListItem';

const DATE_FORMAT = 'DD-MM-YYYY hh:mm A';

interface IPropsAboutDetailPresenter {
  code?: string;
  title?: string;
  description?: string;
  conditions?: string[];

  discountAmount?: number;
  discountType?: string;
  maximumDiscountAmount?: number;
  expiredAt?: string;
  startedAt?: string;
  membershipStartedAt?: string;
  minimumSpendThb?: number;
  redeemedCounts?: number;
  maximumRedeemedCounts?: number;
  status?: PintoVoucherStatus;
  participatingItems?: PintoVoucherParticipatingItem;
}

function AboutDetailPresenter(props: IPropsAboutDetailPresenter) {
  const {
    code,
    title,
    conditions,
    description,
    discountAmount,
    discountType,
    maximumDiscountAmount,
    expiredAt,
    startedAt,
    membershipStartedAt,
    minimumSpendThb,
    status,
    redeemedCounts,
    maximumRedeemedCounts,
    participatingItems,
  } = props;

  return (
    <Card className="flex flex-col space-y-16">
      <div className="text-22">Detail</div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Name</div>
        <div className="text-colorsBrandRed01Primary">{title}</div>
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Code</div>
        <div>{code}</div>
      </div>
      {status && (
        <div className="flex space-x-24">
          <div className="flex w-[200px] font-tt">Status</div>
          <div className={classNames(COUPON_STATUS_CLASS_NAME[status])}>
            <span>{COUPON_STATUS[status]}</span>
          </div>
        </div>
      )}

      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Minimum Spend Thb</div>
        <div>{numberWithCommasWithDecimalPlace(minimumSpendThb) || '-'}</div>
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Discount Amount</div>
        <div>{numberWithCommasWithDecimalPlace(discountAmount) || '-'}</div>
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Discount Type</div>
        <div>{discountType || '-'}</div>
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Maximum Discount Amount</div>
        <div>{maximumDiscountAmount ? numberWithCommasWithDecimalPlace(maximumDiscountAmount) : '-'}</div>
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">VIP Start Date</div>
        <div>{membershipStartedAt ? getDayTime(membershipStartedAt || '', DATE_FORMAT) : '-'}</div>
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Start Date</div>
        <div>{startedAt ? getDayTime(startedAt || '', DATE_FORMAT) : '-'}</div>
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">End Date</div>
        <div>{expiredAt ? getDayTime(expiredAt || '', DATE_FORMAT) : 'ไม่มีวันหมดอายุ'}</div>
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Description</div>
        <div>{description || '-'}</div>
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Conditions</div>
        <ul className="flex flex-col flex-1 space-y-4 list-disc pl-[18px]">
          {conditions && conditions.length > 0 ? (
            conditions?.map((condition, idx) => <li key={idx}>{condition}</li>)
          ) : (
            <div className="text-colorsBrandWarmBlack02LabelSecondary">-</div>
          )}
        </ul>
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Redeemed Counts</div>
        <div>{redeemedCounts ? numberWithCommas(redeemedCounts) : '-'}</div>
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Maximum Redeemed Counts</div>
        <div>{maximumRedeemedCounts ? numberWithCommas(maximumRedeemedCounts) : '-'}</div>
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Participating Item Type</div>
        <div>{participatingItems?.itemType ? participatingItems?.itemType : '-'}</div>
      </div>
    </Card>
  );
}

export default AboutDetailPresenter;
