import React from 'react';

import Pagination from '../../../components/Pagination';
import CouponListItem, { IPropsCouponListItem } from '../components/CouponListItem';

interface IPropsCouponListTablePresenter {
  currentPage: number;
  pageLimit?: number;
  totalCount?: number;
  couponLists?: IPropsCouponListItem[];
  onChangePage: (page: number) => void;
  onDelete: (couponCode: string) => void;
  onToggleEnable: (couponCode: string, isEnable: boolean) => void;
}

const PAGE_LIMIT = 10;

const CouponListTablePresenter = ({ couponLists, pageLimit, totalCount, currentPage, onChangePage, onDelete, onToggleEnable }: IPropsCouponListTablePresenter) => {
  return (
    <div className="overflow-x-auto overflow-y-hidden">
      <table className="table w-full">
        <thead>
          <tr>
            <th>Name</th>
            <td>Min Spend</td>
            <td>Discount</td>
            <td>Maximum Discount</td>
            <td>Date / Time</td>
            <td>Membership</td>
            <td>Status</td>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {couponLists?.map((coupon) => (
            <CouponListItem {...coupon} key={coupon._id} onDelete={() => onDelete(coupon._id)} onToggleEnable={() => onToggleEnable(coupon._id, coupon.isEnable)} />
          ))}
        </tbody>
      </table>

      <div className="flex items-center justify-between mt-12">
        <span className="text-systemGrays02LabelSecondary">แสดงผล 10 รายการต่อหน้า</span>

        {(totalCount || 0) > 0 && <Pagination currentPage={currentPage} totalCount={totalCount || 0} pageSize={pageLimit || PAGE_LIMIT} onPageChange={onChangePage} />}
      </div>
    </div>
  );
};

export default CouponListTablePresenter;
