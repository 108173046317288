import { useCallback, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';

import UploadCouponErrorModal, { IErrorItem } from '../../app/coupons/modals/UploadCouponErrorModal';
import CouponDetailPresenter from '../../app/coupons/presenters/CouponDetailPresenter';
import CouponFormObj, { COUPON_FIELD_KEYS, ICouponFields } from '../../app/coupons/validates/couponSchema';
import ConfirmModal from '../../app/modals/ConfirmModal';
import Breadcrumbs from '../../components/Breadcrumbs';
import Card from '../../components/Card';
import ToastComponent, { IToastType } from '../../components/Toast/ToastComponent';
import { UploadPintoVoucherInfoError, UploadPintoVouchersInput } from '../../core/graphql/types';
import useMutatePintoVouchersUpload from '../../hooks/useMutationPintoVouchersUpload';
import { convertFileToBase64, downloadCSV } from '../../utils/blob';
import useHookForm from '../../utils/form/useHookForm';

const CSV_URL_PATH = '../../a/template/pinto-voucher-template.csv';

const UploadCouponsController = () => {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const discountId = searchParams.get('id');

  const { methods, onValidate, setValue, watch } = useHookForm<ICouponFields>({
    objectShapeForm: CouponFormObj,
    mode: 'onChange',
  });

  const [title] = watch([COUPON_FIELD_KEYS.TITLE]);

  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showConfirmTerminateDiscount, setShowConfirmTerminateDiscount] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [selectedCSVFile, setSelectedCSVFile] = useState<File | undefined>(undefined);

  const { onMutatePintoVouchersUpload } = useMutatePintoVouchersUpload();

  const [errorItems, setErrorsItems] = useState<IErrorItem[] | undefined>(undefined);

  const onSelectCSVFile = useCallback(
    (file: File | undefined) => {
      setSelectedCSVFile(file);
      setValue(COUPON_FIELD_KEYS.FILE_NAME, file?.name as never, {
        shouldValidate: true,
      });
    },
    [setValue],
  );

  const onSubmitScheduledDiscount = useCallback(async () => {
    setShowConfirmModal(false);

    if (!selectedCSVFile || !title) return;

    const fileBase64 = await convertFileToBase64(selectedCSVFile);
    const input: UploadPintoVouchersInput = {
      adminNote: title,
      fileName: selectedCSVFile?.name,
      fileData: fileBase64 as string,
    };

    const { data, errors } = await onMutatePintoVouchersUpload(input);

    if (data?.uploadPintoVouchers.errors) {
      const errorItems = data.uploadPintoVouchers.errors?.map(({ code, errors }: UploadPintoVoucherInfoError) => ({
        code,
        errorMessages: errors,
      })) as IErrorItem[];
      console.log(errorItems);

      setErrorsItems(errorItems);
      setShowErrorModal(true);
      return;
    } else if (data?.uploadPintoVouchers.valid) {
      ToastComponent({ label: 'Publish successfully ', type: IToastType.SUCCESS });
      history.push('/coupons');
    } else if (errors) {
      const [createError] = errors;
      ToastComponent({ label: createError.message, type: IToastType.ERROR });
    }
  }, [history, onMutatePintoVouchersUpload, selectedCSVFile, title]);

  const handlePublishScheduledDiscount = useCallback(async () => {
    const isValid = await onValidate();

    if (isValid) {
      setShowConfirmModal(true);
    }
  }, [onValidate]);

  const onClickDownloadCSVTemplate = useCallback(() => {
    downloadCSV(CSV_URL_PATH, 'pinto-voucher-template.csv');
  }, []);

  return (
    <div>
      <>
        {showConfirmModal && (
          <ConfirmModal
            onClose={() => setShowConfirmModal(false)}
            onConFirm={onSubmitScheduledDiscount}
            title="Publish"
            subTitle="Schedule will automatically publish when in due course,
          Are you confirm to publish?"
          />
        )}

        {showConfirmTerminateDiscount && (
          <ConfirmModal
            onClose={() => setShowConfirmTerminateDiscount(false)}
            onConFirm={() => undefined}
            title="Terminate"
            subTitle="Discount will be cancel and price of e-book will change
          to original price. Are you confirm to terminate?"
          />
        )}

        {showErrorModal && (
          <UploadCouponErrorModal
            onConFirm={() => {
              setShowErrorModal(false);
            }}
            errorItems={errorItems}
          />
        )}

        <div className="sticky top-0 z-50 px-5 bg-componentsBgGrouped02">
          {/* section: breadcrumbs */}
          <Breadcrumbs items={[{ name: 'Coupons', url: '/coupons' }, { name: `${discountId ? 'Edit' : 'Create New'} Coupon` }]} />

          {/* section: title */}
          <div className="flex justify-between pt-5">
            <h1 className="font-dbh text-[28px]">{`${discountId ? 'Edit' : 'Create New'} Coupon`}</h1>

            <div className="space-x-12">
              <Link to="/coupons">
                <button className="btn btn-outline">CANCEL</button>
              </Link>
              <button className="btn btn-error" onClick={handlePublishScheduledDiscount} disabled={false}>
                Submit
              </button>
            </div>
          </div>
        </div>

        <Card className="flex flex-row w-full space-x-24">
          <div className="flex-1">
            <FormProvider {...methods}>
              <CouponDetailPresenter
                selectedCSVFile={selectedCSVFile}
                onSelectCSVFile={onSelectCSVFile}
                onClickDownloadCSVTemplate={onClickDownloadCSVTemplate}
                fileName={undefined}
                fileUrl={undefined}
              />
            </FormProvider>
          </div>
        </Card>
      </>
    </div>
  );
};

export default UploadCouponsController;
