import { isNil, omitBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { FiDownload, FiPlus, FiLink } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { IPropsCouponListItem } from '../../app/coupons/components/CouponListItem';
import CouponListTablePresenter from '../../app/coupons/presenters/CouponListTablePresenter';
import FilterAndSearchPresenter from '../../app/coupons/presenters/FilterAndSearchPresenter';
import { PINTO_BOOK_BASE_URL } from '../../app/ebooks/presenters/AboutDetailPresenter';
import ConfirmModal from '../../app/modals/ConfirmModal';
import ToastComponent, { IToastType } from '../../components/Toast/ToastComponent';
import { PintoVoucherDiscountType, PintoVoucherStatus } from '../../core/graphql/types';
import useMutationPintoVouchersDelete from '../../hooks/useMutationPintoVouchersDelete';
import useMutationPintoVouchersUpdate from '../../hooks/useMutationPintoVouchersUpdate';
import useQueryPintoVouchers from '../../hooks/useQueryPintoVouchers';
import { downloadCSV } from '../../utils/blob';

const StatusOptions = [
  {
    label: 'All status',
    value: null,
  },
  {
    label: 'Scheduled',
    value: PintoVoucherStatus.Scheduled,
  },
  {
    label: 'Active',
    value: PintoVoucherStatus.Active,
  },
  {
    label: 'Expired',
    value: PintoVoucherStatus.Expired,
  },
];

const CSV_URL_PATH = '../../a/template/pinto-voucher-template.csv';

const CouponPage = () => {
  const { pintoVouchers, fetchPintoVouchers, isLoadingPintoVouchers } = useQueryPintoVouchers();
  const { deleteVouchers } = useMutationPintoVouchersDelete();
  const { updateVouchers } = useMutationPintoVouchersUpdate();

  const [searchText, setSearchText] = useState<string | undefined>(undefined);

  const [status, setStatus] = useState<PintoVoucherStatus | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);

  //modal
  const [showConfirmDeleteCoupon, setShowConfirmDeleteCoupon] = useState<boolean>(false);
  const [couponId, setCouponId] = useState<string | undefined>(undefined);

  const handleChangePage = useCallback(
    (page: number) => {
      const offset = (page - 1) * 10;
      setCurrentPage(page);
      const filter = omitBy({ status, offset }, isNil);
      fetchPintoVouchers(filter);
    },
    [fetchPintoVouchers, status],
  );

  useEffect(() => {
    const filter = omitBy({ status, q: searchText }, isNil);
    fetchPintoVouchers(filter);
  }, [fetchPintoVouchers, searchText, status]);

  const handleSetSearchText = useCallback((text) => {
    setSearchText(text);
  }, []);

  const handleOnDelete = useCallback((id: string) => {
    setShowConfirmDeleteCoupon(true);
    setCouponId(id);
  }, []);

  const onSubmitDeleteCoupon = useCallback(() => {
    if (couponId) {
      deleteVouchers(couponId);
      setShowConfirmDeleteCoupon(false);
      ToastComponent({
        label: 'Terminate successfully',
        type: IToastType.SUCCESS,
      });
    }
  }, [deleteVouchers, couponId]);

  const onHideCoupon = useCallback(
    async (code) => {
      if (code) {
        const isSuccess = await updateVouchers(code, { enabled: false });
        if (isSuccess) {
          ToastComponent({ label: 'Hide coupon successfully ', type: IToastType.SUCCESS });
        }
      }
    },
    [updateVouchers],
  );

  const onUnHideCoupon = useCallback(
    async (code) => {
      if (code) {
        const isSuccess = await updateVouchers(code, { enabled: true });
        if (isSuccess) {
          ToastComponent({ label: 'Publish coupon successfully ', type: IToastType.SUCCESS });
        }
      }
    },
    [updateVouchers],
  );

  const onConfirmHideUnHideCoupon = useCallback(
    (code: string, display: boolean) => {
      if (display) {
        onHideCoupon(code);
      } else {
        onUnHideCoupon(code);
      }
    },
    [onHideCoupon, onUnHideCoupon],
  );

  return (
    <div className="mx-auto space-y-24">
      {showConfirmDeleteCoupon && (
        <ConfirmModal onClose={() => setShowConfirmDeleteCoupon(false)} onConFirm={() => onSubmitDeleteCoupon()} title="Terminate" subTitle="Are you confirm to delete?" />
      )}

      <div className="space-y-24 divider-y">
        {/* section: title */}
        <div className="flex justify-between py-5">
          <h1 className="font-dbh text-[28px]">Coupons</h1>

          <div className="space-x-16">
            <button className="space-x-8 btn btn-error btn-ghost" onClick={() => window.open(`${PINTO_BOOK_BASE_URL}/all-coupon`)}>
              <FiLink className="font-bold text-24" />
              <span>หน้ารวมคูปอง</span>
            </button>

            <button className="space-x-8 btn btn-error btn-outline" onClick={() => downloadCSV(CSV_URL_PATH, 'pinto-voucher-template.csv')}>
              <FiDownload className="font-bold text-24" />
              <span>download Template</span>
            </button>

            <Link to="/coupons/create">
              <button className="space-x-8 btn btn-error">
                <FiPlus className="font-bold text-24" />
                <span>Create New Coupons</span>
              </button>
            </Link>
          </div>
        </div>
        {/* filter and search */}
        <div className="space-y-8">
          <div className="text-colorsBrandWarmBlack02LabelSecondary">Sort by: Created Date</div>
          <FilterAndSearchPresenter onSetSearchText={handleSetSearchText} status={status} onSetStatus={(_status) => setStatus(_status)} statusOptions={StatusOptions} />
        </div>
      </div>

      {/* table content */}
      <div>
        {isLoadingPintoVouchers && <div className="w-full btn btn-outline btn-disabled bg-systemGrays04LabelQuaternary border-none loading h-[100px]" />}

        {!isLoadingPintoVouchers && (
          <>
            {(pintoVouchers?.edges?.length || 0) === 0 && (
              <div className="flex items-center justify-center w-full h-[60vh] text-colorsBrandWarmBlack02LabelSecondary">No list of {status} coupon</div>
            )}

            {(pintoVouchers?.edges?.length || 0) !== 0 && (
              <CouponListTablePresenter
                couponLists={pintoVouchers?.edges.map(
                  ({ node }) =>
                    ({
                      _id: node.code,
                      isEnable: node.enabled,
                      isMembershipOnly: node?.isMembershipOnly,
                      membershipStartedAt: node?.membershipStartedAt,
                      startedAt: node?.startedAt,
                      expiredAt: node?.expiredAt,
                      minimumSpendThb: node.minimumSpendThb,
                      discount:
                        node.discountType === PintoVoucherDiscountType.Thb
                          ? `${node.discountThb} THB`
                          : `${node.discountType === PintoVoucherDiscountType.PercentageCoinBack ? 'CB' : ''} ${node.discountAmount} %`,
                      maximumDiscountAmount: node?.maximumDiscountAmount,
                      title: node.title,
                      description: node.description,
                      conditions: node.conditions,
                      status: node.status as unknown as PintoVoucherStatus,
                      maximumRedeemedCounts: node?.isMembershipOnly ? node.maximumRedeemedCountsPerMembership : node.maximumRedeemedCountsPerUser || node.maximumRedeemedCounts,
                      redeemedCounts: node?.isMembershipOnly ? 0 : node.redeemedCounts,
                    } as IPropsCouponListItem),
                )}
                currentPage={currentPage}
                totalCount={pintoVouchers?.totalCount}
                onChangePage={handleChangePage}
                onDelete={handleOnDelete}
                onToggleEnable={onConfirmHideUnHideCoupon}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CouponPage;
