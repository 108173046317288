import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useCookie, useLocalStorage } from 'react-use';

import LoginForm from './app/login/LoginForm';
import images from './assets/images';
import Navbar from './components/Navbar';
import ToastContainer from './components/Toast/ToastContainer';
import ToggleThemeButton from './components/ToggleThemeButton';
import { cookieName } from './core/cookieName';
import Icon from './fictionlog/components/Icon';
import AllModals from './modals/AllModals';
import BundlePage from './pages/bundles';
import CreateBundlePage from './pages/bundles/create';
import CouponPage from './pages/coupons';
import CreateCouponPage from './pages/coupons/create';
import CouponDetailPage from './pages/coupons/detail';
import DiscountPage from './pages/discount';
import EditDiscountPage from './pages/discount/edit';
import EbooksPage from './pages/ebooks';
import EbookDetailPage from './pages/ebooks/detail';
import EditEbooksPage from './pages/ebooks/edit';
import FeedsPage from './pages/feeds';
import FeedBuilderPage from './pages/feeds/edit';
import GenPageForAdsPage from './pages/genPageForAds';
import CreateAdsPage from './pages/genPageForAds/edit';
import HomePage from './pages/home';
import SectionsPage from './pages/sections';
import SectionBuilderPage from './pages/sections/edit';
// pages
import SubscriptionsPage from './pages/subscriptions';
import EditSubscriptionPage from './pages/subscriptions/edit';
import EndOfChapterPage from './pages/suggestProducts/endOfChapter';
import EditEndOfChapter from './pages/suggestProducts/endOfChapter/edit';
import LibraryAdPage from './pages/suggestProducts/libraryAd';
import EditLibraryAdPage from './pages/suggestProducts/libraryAd/edit';
import PopupPage from './pages/suggestProducts/popUp';
import EditPopupPage from './pages/suggestProducts/popUp/edit';
// PINTO
import UploadToolsPage from './pages/uploadTools';
import { ColorSchemeCSSVariable, IPropsColorSchemeCSSVariable } from './styles/ColorSchemeCSSVariable';
import Fonts from './styles/Fonts';
import { deleteCookie, setCookie } from './utils/cookie';

export type IRoutes = {
  component?: JSX.Element;
  path?: string;
  name: string;
  hideInMenu?: boolean;
  nestedRoutes?: {
    component: JSX.Element;
    path: string;
    name: string;
    hideInMenu?: boolean;
  }[];
}[];

const LOCAL_STORAGE_PLATFORM_KEY = 'last_active_platform';
enum PLATFORM {
  FICTIONLOG = 'Fictionlog',
  PINTO = 'Pinto',
}

export const routes: IRoutes = [
  { component: <HomePage />, path: '/', name: 'Home' },
  {
    name: 'Suggest Products',
    nestedRoutes: [
      {
        component: <LibraryAdPage />,
        name: 'Library ad',
        path: '/suggestProducts/libraryAd',
      },
      {
        hideInMenu: true,
        component: <EditLibraryAdPage />,
        name: 'Edit Library ad',
        path: '/suggestProducts/libraryAd/edit',
      },
      {
        component: <EndOfChapterPage />,
        name: 'End of chapter',
        path: '/suggestProducts/endOfChapter',
      },
      {
        hideInMenu: true,
        component: <EditEndOfChapter />,
        name: 'Edit End of chapter',
        path: '/suggestProducts/endOfChapter/edit',
      },
      { component: <PopupPage />, name: 'Popup', path: '/suggestProducts/popup' },
      { hideInMenu: true, component: <EditPopupPage />, name: 'Edit Popup', path: '/suggestProducts/popup/edit' },
    ],
  },
  { component: <GenPageForAdsPage />, path: '/gen-page-for-ads', name: 'Gen Page for Ads' },
  {
    hideInMenu: true,
    component: <CreateAdsPage />,
    name: 'Ads Builder',
    path: '/gen-page-for-ads/edit',
  },
];

export const pintoRoutes: IRoutes = [
  { component: <HomePage />, path: '/', name: 'Home' },
  {
    name: 'Feed & Section',
    nestedRoutes: [
      {
        component: <FeedsPage />,
        name: 'Feeds',
        path: '/feedAndSection/feeds',
      },
      {
        hideInMenu: true,
        component: <FeedBuilderPage />,
        name: 'Feed Builder',
        path: '/feedAndSection/feeds/edit',
      },
      {
        component: <SectionsPage />,
        name: 'Sections',
        path: '/feedAndSection/sections',
      },
      {
        hideInMenu: true,
        component: <SectionBuilderPage />,
        name: 'Section Builder',
        path: '/feedAndSection/sections/edit',
      },
    ],
  },
  { component: <DiscountPage />, path: '/discount', name: 'Discounts' },
  { hideInMenu: true, component: <EditDiscountPage />, name: 'Edit Discount', path: '/discount/edit' },
  { component: <CouponPage />, path: '/coupons', name: 'Coupons' },
  { hideInMenu: true, component: <CreateCouponPage />, path: '/coupons/create', name: 'Upload New Coupons' },
  { hideInMenu: true, component: <CouponDetailPage />, path: '/coupons/detail', name: 'Upload New Coupons' },
  { component: <EbooksPage />, path: '/ebooks', name: 'Ebooks' },
  {
    hideInMenu: true,
    component: <EditEbooksPage />,
    name: 'Create Ebooks',
    path: '/ebooks/edit',
  },
  {
    hideInMenu: true,
    component: <EbookDetailPage />,
    name: 'Ebook Detail',
    path: '/ebooks/detail',
  },
  { component: <UploadToolsPage />, path: '/uploadTools', name: 'Upload Tools' },
  { component: <BundlePage />, path: '/bundles', name: 'Bundles' },
  { hideInMenu: true, component: <CreateBundlePage />, name: 'Create Bundle', path: '/bundles/create' },
  { component: <SubscriptionsPage />, path: '/subscriptions', name: 'Subscriptions' },
  {
    hideInMenu: true,
    component: <EditSubscriptionPage />,
    name: 'Create Subscription',
    path: '/subscriptions/edit',
  },
];

function App() {
  const [accessToken] = useCookie(cookieName.ACCESS_TOKEN);
  const [cookieCurrentTheme] = useCookie(cookieName.THEME);
  const [theme, setTheme] = useState<IPropsColorSchemeCSSVariable['theme']>((cookieCurrentTheme as IPropsColorSchemeCSSVariable['theme']) || 'DAY');
  const [isAuth, setIsAuth] = useState<boolean>(!!accessToken);
  const [platform, setPlatform] = useState<PLATFORM>(PLATFORM.FICTIONLOG);
  const [lastActivePlatform, setLastActivePlatform] = useLocalStorage<PLATFORM>(LOCAL_STORAGE_PLATFORM_KEY, PLATFORM.FICTIONLOG);

  useEffect(() => {
    setIsAuth(!!accessToken);
  }, [accessToken]);

  useEffect(() => {
    if (lastActivePlatform) setPlatform(lastActivePlatform);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLastActivePlatform(platform);
  }, [platform, setLastActivePlatform]);

  const onLogout = useCallback(() => {
    const isConfirmLogout = confirm('Confirm to logout?');
    if (isConfirmLogout) {
      deleteCookie(cookieName.ACCESS_TOKEN);
      window.location.reload();
    }
  }, []);

  const onChangeTheme = useCallback(() => {
    const newTheme = theme === 'DAY' ? 'NIGHT' : 'DAY';
    setTheme(newTheme);
    setCookie(cookieName.THEME, newTheme);
  }, [theme]);

  return (
    <div data-theme={theme === 'DAY' ? 'light' : 'luxury'}>
      {/* setup css variables and fontface */}
      <ColorSchemeCSSVariable theme={theme} />
      <Fonts />
      <ToastContainer />
      <AllModals />
      {!isAuth && <LoginForm />}

      {isAuth && (
        <div className="flex flex-col w-screen md:flex-row">
          {/* menu */}
          <div className="w-[95px] md:h-[100vh] py-24 items-center sticky flex flex-row md:flex-col justify-between top-0 border-r border-solid border-colorsBrandWarmBlack04LabelQuaternary bg-componentsBgGrouped02">
            <label htmlFor="my-drawer" className="flex flex-row space-x-12 drawer-button md:flex-col md:space-x-0 md:space-y-12">
              <div
                className={classNames('w-[55px] h-[55px] rounded-xl p-4 transition-all duration-150 ease-in-out', {
                  'border-[3px] border-solid border-colorsBrandWarmBlack01LabelPrimary p-[3px] shadow-md': platform === PLATFORM.FICTIONLOG,
                  'hover:border-[3px] hover:border-solid hover:border-gray-200 hover:p-[3px]': platform !== PLATFORM.FICTIONLOG,
                })}
                onClick={() => setPlatform(PLATFORM.FICTIONLOG)}
              >
                <div className="flex flex-col items-center justify-center w-full h-full overflow-hidden rounded-md cursor-pointer bg-colorsBrandOrange01Primary">
                  <img src={images.fictionlog.workspaceLogo} alt={PLATFORM.FICTIONLOG} />
                </div>
              </div>
              <div
                className={classNames('w-[55px] h-[55px] rounded-xl p-4 transition-all duration-150 ease-in-out', {
                  'border-[3px] border-solid border-colorsBrandWarmBlack01LabelPrimary p-[3px] shadow-md': platform === PLATFORM.PINTO,
                  'hover:border-[3px] hover:border-solid hover:border-gray-200 hover:p-[3px]': platform !== PLATFORM.PINTO,
                })}
                onClick={() => setPlatform(PLATFORM.PINTO)}
              >
                <div className="flex flex-col items-center justify-center w-full h-full overflow-hidden rounded-md cursor-pointer bg-colorsBrandRed01Primary">
                  <img src={images.pinto.workspaceLogo} alt={PLATFORM.PINTO} />
                </div>
              </div>
            </label>
            <div className="flex flex-row space-x-12 md:space-x-0 md:space-y-12 md:flex-col">
              {/* theme */}
              <ToggleThemeButton currentTheme={theme} toggleTheme={onChangeTheme} />

              {/* logout */}
              <div
                className="flex items-center px-8 py-8 text-sm rounded-lg cursor-pointer font-dbh text-systemGrays01LabelPrimary hover:bg-systemGrays05FillPrimary focus:outline-none focus:shadow-outline"
                onClick={onLogout}
                aria-hidden="true"
              >
                <Icon name="logout" size="20px" className="mr-4" />
                Logout
              </div>
            </div>
          </div>

          {/* drawer */}
          <div className="flex flex-1 min-h-screen">
            <div className="w-full drawer">
              <input id="my-drawer" type="checkbox" className="drawer-toggle" />
              <div className="drawer-content bg-componentsBgGrouped01">
                <div className="min-h-screen p-3 bg-componentsBgGrouped01 text-systemGrays01LabelPrimary">
                  <Switch>
                    {[...routes, ...pintoRoutes].map((route) => {
                      const items = [];
                      if (route.nestedRoutes) {
                        route.nestedRoutes.map((nestedRoute) => {
                          items.push(
                            <Route key={nestedRoute.path} exact path={nestedRoute.path}>
                              {nestedRoute.component}
                            </Route>,
                          );
                          return null;
                        });
                      }
                      if (route.path) {
                        items.push(
                          <Route key={route.path} exact path={route.path}>
                            {route.component}
                          </Route>,
                        );
                      }
                      return items;
                    })}
                  </Switch>
                </div>
              </div>
              <div className="drawer-side">
                <label htmlFor="my-drawer" className="drawer-overlay"></label>
                <Navbar theme={theme} platform={platform} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
