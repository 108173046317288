import { useCallback } from 'react';

import { usePintoVoucherLazyQuery } from '../core/graphql/operation-hooks';

const useQueryPintoVoucher = () => {
  const [queryPintoVoucher, { data, loading }] = usePintoVoucherLazyQuery();

  const onFetchPintoVoucher = useCallback(
    async (code: string) => {
      queryPintoVoucher({ variables: { code } });
    },
    [queryPintoVoucher],
  );
  return {
    onFetchPintoVoucher,
    pintoVoucherData: data?.pintoVoucher,
    isLoadingPintoVoucher: loading,
  };
};

export default useQueryPintoVoucher;
